<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title"> Work Classic </h4>
          <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                <li class="breadcrumb-item"><a href="#">Pages</a></li>
                <li class="breadcrumb-item"><a href="#">Work</a></li>
                <li class="breadcrumb-item active" aria-current="page">Classic</li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Hero End -->

<!-- Start -->
<section class="section">
  <div class="container">
    <div class="row">
      <ul class="col container-filter list-unstyled categories-filter text-center mb-0" id="filter">
        <li class="list-inline-item"><a class="categories border d-block text-dark rounded active"
            (click)="activeCategory('all')" [ngClass]="{'active': galleryFilter ==='all'}">All</a></li>
        <li class="list-inline-item"><a class="categories border d-block text-dark rounded"
            (click)="activeCategory('branding')" [ngClass]="{'active': galleryFilter ==='branding'}">Branding</a></li>
        <li class="list-inline-item"><a class="categories border d-block text-dark rounded"
            (click)="activeCategory('designing')" [ngClass]="{'active': galleryFilter ==='designing'}">Designing</a>
        </li>
        <li class="list-inline-item"><a class="categories border d-block text-dark rounded"
            (click)="activeCategory('photography')" [ngClass]="{'active': galleryFilter
            ==='photography'}">Photography</a></li>
        <li class="list-inline-item"><a class="categories border d-block text-dark rounded"
            (click)="activeCategory('development')" [ngClass]="{'active': galleryFilter
            ==='development'}">Development</a></li>
      </ul>
    </div>
    <!--end row-->

    <div class="row projects-wrapper">
      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2 branding" *ngFor="let item of filterredImages">
        <div class="card border-0 work-container work-classic">
          <div class="card-body p-0">
            <a href="page-work-detail"><img src="{{item.image}}" class="img-fluid rounded work-image" alt=""></a>
            <div class="content pt-3">
              <h5 class="mb-0"><a href="page-work-detail" class="text-dark title">{{item.title}}</a></h5>
              <h6 class="text-muted tag mb-0">{{item.type}}</h6>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top"
  [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
