<!-- Hero Start -->
<section class="bg-half-170 d-table w-100" id="home">
    <div class="container">
        <div class="row mt-5 align-items-center position-relative" style="z-index: 1;">
            <div class="col-lg-6">
                <div class="title-heading text-center text-lg-start">
                    <span class="badge rounded-lg bg-soft-primary">Videocall</span>
                    <h4 class="heading fw-bold mb-3 mt-3">Team Chat, <br> Virtual Meetings, <br> File sharing and
                        more...</h4>
                    <p class="para-desc text-muted mx-auto mx-lg-start mb-0">Launch your campaign and benefit from our
                        expertise on designing and managing conversion centered bootstrap v5 html page.</p>
                    <div class="mt-3">
                        <a href="javascript:void(0)" class="btn btn-primary me-2 mt-2">Try For Free</a>
                        <a href="javascript:void(0)" class="btn btn-soft-primary mt-2">Get a live video</a>
                        <p class="mt-3 mb-0 text-muted">*No credit card required</p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-6 mt-4 pt-2 mt-lg-0 pt-lg-0">
                <div class="card border-0 shadow rounded ms-lg-4 overflow-hidden">
                    <div class="d-flex p-2 bg-light justify-content-between align-items-center">
                        <div>
                            <a href="javascript:void(0)" class="text-danger"><i class="mdi mdi-circle"></i></a>
                            <a href="javascript:void(0)" class="text-warning"><i class="mdi mdi-circle"></i></a>
                            <a href="javascript:void(0)" class="text-success"><i class="mdi mdi-circle"></i></a>
                        </div>

                        <small class="fw-bold"><i class="mdi mdi-circle-medium text-success"></i> Landrick</small>
                    </div>
                    <div class="bg-light px-2 position-relative">
                        <video class="w-100 rounded" controls autoplay loop>
                            <source src="assets/images/video/call.mp4" type="video/mp4">
                        </video>

                        <div class="position-absolute top-0 start-0">
                            <div class="mt-2 ms-3">
                                <span class="badge bg-danger">LIVE</span>
                                <small class="mb-0 text-white title-dark">4 Attendance</small>
                            </div>
                        </div>
                    </div>

                    <div class="p-2 bg-light">
                        <p class="text-muted mb-0"><b>Note:</b> This video is only for demo purposes.</p>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Partners start -->
<section class="py-5 border-top">
    <div class="container">
        <div class="row justify-content-center" id="counter">
            <div class="col-12 text-center">
                <h4 class="mb-0 mt-3">Trusted client by over <span class="text-primary fw-bold"> <span
                            class="counter-value" [CountTo]="1100" [from]="100" [duration]="4"></span>+</span> of the
                    world’s</h4>
                <p class="text-muted mb-0 mx-auto">Trust <span class="text-primary fw-bold">Landrick</span> to host
                    webinars and online meetings</p>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
        <div class="mt-5">
            <app-clients-logo></app-clients-logo>
        </div>

        <div class="row mt-5 justify-content-center">
            <div class="col-12">
                <div class="text-center">
                    <ul class="list-unstyled mb-0 h6">
                        <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                        <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                        <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                        <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                        <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                        <li class="list-inline-item">5 Star <span class="text-muted">(380 Review received)</span></li>
                    </ul>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Partners End -->

<!-- Start -->
<section class="section bg-light">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center mb-4 pb-2">
                    <h4 class="title mb-4">Video communication for any context</h4>
                    <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.</p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row justify-content-center">
            <div class="col-xl-10">
                <div class="row">
                    <div class="col-md-6 mt-4 pt-2">
                        <div class="d-flex features feature-clean p-4 bg-white shadow rounded">
                            <div class="icons text-primary d-block text-center rounded">
                                <i class="uil uil-comment-verify d-block rounded h3 mb-0"></i>
                            </div>
                            <div class="flex-1 content ms-4">
                                <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Make one-to-one
                                        calls</a></h5>
                                <p class="text-muted mb-0">The most well-known dummy text is the, which is said to have
                                    originated in the 16th century.</p>
                            </div>
                        </div>
                    </div>
                    <!--end col-->

                    <div class="col-md-6 mt-4 pt-2">
                        <div class="d-flex features feature-clean p-4 bg-white shadow rounded">
                            <div class="icons text-primary d-block text-center rounded">
                                <i class="uil uil-database-alt d-block rounded h3 mb-0"></i>
                            </div>
                            <div class="flex-1 content ms-4">
                                <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Duo uses end-to-end
                                        encryption</a></h5>
                                <p class="text-muted mb-0">The most well-known dummy text is the, which is said to have
                                    originated in the 16th century.</p>
                            </div>
                        </div>
                    </div>
                    <!--end col-->

                    <div class="col-md-6 mt-4 pt-2">
                        <div class="d-flex features feature-clean p-4 bg-white shadow rounded">
                            <div class="icons text-primary d-block text-center rounded">
                                <i class="uil uil-image d-block rounded h3 mb-0"></i>
                            </div>
                            <div class="flex-1 content ms-4">
                                <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Let friends know that
                                        you’ve joined</a></h5>
                                <p class="text-muted mb-0">The most well-known dummy text is the, which is said to have
                                    originated in the 16th century.</p>
                            </div>
                        </div>
                    </div>
                    <!--end col-->

                    <div class="col-md-6 mt-4 pt-2">
                        <div class="d-flex features feature-clean p-4 bg-white shadow rounded">
                            <div class="icons text-primary d-block text-center rounded">
                                <i class="uil uil-video d-block rounded h3 mb-0"></i>
                            </div>
                            <div class="flex-1 content ms-4">
                                <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Set up groups for your
                                        friends & family</a></h5>
                                <p class="text-muted mb-0">The most well-known dummy text is the, which is said to have
                                    originated in the 16th century.</p>
                            </div>
                        </div>
                    </div>
                    <!--end col-->

                    <div class="col-12 mt-4 pt-2 text-center">
                        <a href="javascript:void(0)" class="text-primary h6">Explore features <i-feather
                                name="arrow-right" class="fea icon-sm"></i-feather></a>
                    </div>
                    <!--end col-->
                </div>
                <!--end row-->
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="card rounded border-0 overflow-hidden">
                    <div class="row g-0">
                        <div class="col-md-6">
                            <div class="position-relative">
                                <a href="javascript:void(0)"><img src="assets/images/video/01.jpg" class="img-fluid"
                                        alt=""></a>

                                <div class="position-absolute bottom-0 start-50 translate-middle-x">
                                    <div class="mb-3">
                                        <a href="javascript:void(0)" class="btn btn-pills btn-icon btn-danger"><i
                                                class="uil uil-phone icons"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--end col-->

                        <div class="col-md-6">
                            <div class="row g-0">
                                <div class="col-md-6 col-4">
                                    <div class="position-relative">
                                        <a href="javascript:void(0)"><img src="assets/images/video/02.jpg"
                                                class="img-fluid" alt=""></a>

                                        <div class="position-absolute bottom-0 start-50 translate-middle-x">
                                            <div class="mb-3">
                                                <a href="javascript:void(0)"
                                                    class="btn btn-pills btn-sm btn-icon btn-primary"><i
                                                        class="uil uil-comment icons"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--end col-->

                                <div class="col-md-6 col-4">
                                    <div class="position-relative">
                                        <a href="javascript:void(0)"><img src="assets/images/video/03.jpg"
                                                class="img-fluid" alt=""></a>

                                        <div class="position-absolute bottom-0 start-50 translate-middle-x">
                                            <div class="mb-3">
                                                <a href="javascript:void(0)"
                                                    class="btn btn-pills btn-sm btn-icon btn-primary"><i
                                                        class="uil uil-comment icons"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--end col-->

                                <div class="col-md-6 col-4">
                                    <div class="position-relative">
                                        <a href="javascript:void(0)"><img src="assets/images/video/05.jpg"
                                                class="img-fluid" alt=""></a>

                                        <div class="position-absolute bottom-0 start-50 translate-middle-x">
                                            <div class="mb-3">
                                                <a href="javascript:void(0)"
                                                    class="btn btn-pills btn-sm btn-icon btn-primary"><i
                                                        class="uil uil-comment icons"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--end col-->

                                <div class="col-md-6 col-4">
                                    <div class="position-relative">
                                        <a href="javascript:void(0)"><img src="assets/images/video/04.jpg"
                                                class="img-fluid" alt=""></a>

                                        <div class="position-absolute bottom-0 start-50 translate-middle-x">
                                            <div class="mb-3">
                                                <a href="javascript:void(0)"
                                                    class="btn btn-pills btn-sm btn-icon btn-primary"><i
                                                        class="uil uil-comment icons"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--end col-->

                                <div class="col-md-6 col-4">
                                    <div class="position-relative">
                                        <a href="javascript:void(0)"><img src="assets/images/video/06.jpg"
                                                class="img-fluid" alt=""></a>

                                        <div class="position-absolute bottom-0 start-50 translate-middle-x">
                                            <div class="mb-3">
                                                <a href="javascript:void(0)"
                                                    class="btn btn-pills btn-sm btn-icon btn-primary"><i
                                                        class="uil uil-comment icons"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--end col-->

                                <div class="col-md-6 col-4">
                                    <div class="position-relative">
                                        <a href="javascript:void(0)"><img src="assets/images/video/07.jpg"
                                                class="img-fluid" alt=""></a>

                                        <div class="position-absolute bottom-0 start-50 translate-middle-x">
                                            <div class="mb-3">
                                                <a href="javascript:void(0)"
                                                    class="btn btn-pills btn-sm btn-icon btn-primary"><i
                                                        class="uil uil-comment icons"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--end col-->
                            </div>
                            <!--end row-->
                        </div>
                        <!--end col-->
                    </div>
                    <!--end row-->
                </div>
                <!--end card-->
            </div>
            <!--end col-->

            <div class="col-lg-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div class="section-title ms-lg-5">
                    <h4 class="title mb-4">Get the group together</h4>
                    <p class="text-muted">Keep the fun times rolling with your weekly game nights over group calling.
                        Available for 50 friends at a time and works on Android phones, iPhones, iPads and tablets. You
                        can also join a group call with just a link.</p>
                    <ul class="list-unstyled text-muted">
                        <li class="mb-0"><span class="text-primary h5 me-2"><i
                                    class="uil uil-check-circle align-middle"></i></span>Digital Marketing Solutions for
                            Tomorrow</li>
                        <li class="mb-0"><span class="text-primary h5 me-2"><i
                                    class="uil uil-check-circle align-middle"></i></span>Create your own skin to match
                            your brand</li>
                    </ul>
                    <a href="javascript:void(0)" class="mt-3 h6 text-primary">Let's Chat Now <i
                            class="uil uil-angle-right-b"></i></a>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End -->

<!-- Start -->
<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center mb-4 pb-2">
                    <h4 class="title mb-4">Some of our happy customers</h4>
                    <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.</p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row justify-content-center">
            <div class="col-lg-12 mt-4">
                <app-testimonial [testimonialData]="testimonialData"></app-testimonial>
            </div>
            <!--end col-->
        </div>
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-6 order-2 order-lg-1 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div class="section-title me-lg-5">
                    <h4 class="title mb-4">Keep calls private with end-to-end encryption</h4>
                    <p class="text-muted">This prevents repetitive patterns from impairing the overall visual impression
                        and facilitates the comparison of different typefaces. Furthermore, it is advantageous when the
                        dummy text is relatively realistic.</p>
                    <ul class="list-unstyled text-muted">
                        <li class="mb-0"><span class="text-primary h5 me-2"><i
                                    class="uil uil-check-circle align-middle"></i></span>Digital Marketing Solutions for
                            Tomorrow</li>
                        <li class="mb-0"><span class="text-primary h5 me-2"><i
                                    class="uil uil-check-circle align-middle"></i></span>Create your own skin to match
                            your brand</li>
                    </ul>
                    <a href="javascript:void(0)" class="mt-3 h6 text-primary">Find Out More <i
                            class="uil uil-angle-right-b"></i></a>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-6 order-1 order-lg-2">
                <img src="assets/images/video/cta.png" class="img-fluid" alt="">
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center">
                    <h4 class="title mb-4">Get started with <span class="text-primary fw-bold">Landrick</span> for free
                    </h4>
                    <p class="text-muted para-desc mx-auto">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.</p>

                    <div class="mt-4">
                        <a href="javascript:void(0)" class="btn btn-primary m-1"><i class="uil uil-apple"></i> App
                            Store</a>
                        <a href="javascript:void(0)" class="btn btn-outline-primary m-1"><i
                                class="uil uil-google-play"></i> Play Store</a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->