<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="page-next-level">
                    <h4 class="title"> {{'SERVICES' | translate}} </h4>
                    <div class="page-next">
                        <nav aria-label="breadcrumb" class="d-inline-block">
                            <ul class="breadcrumb bg-white rounded shadow mb-0">
                                <li class="breadcrumb-item"><a routerLink="/index">{{'COMPANY-NAME' | translate}}</a></li>
                                <li class="breadcrumb-item"><a href="javascript:void(0)">{{'SERVICES' | translate}}</a></li>
                                <li class="breadcrumb-item active" aria-current="page">{{'CMP-SERVICES' | translate}}</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
    </div>
</div>
<!--Shape End-->

<!-- Feature Start -->
<section class="section">
    <div class="container">
        <div class="row">
            <div class="col-md-4 col-12 mt-5">
                <div class="features features text-center">
                    <div class="image position-relative d-inline-block">
                        <img src="assets/images/icon/mobile.png" width="50" height="auto" alt="">
                        <!-- <i class=""></i> -->
                    </div>

                    <div class="content mt-4">
                        <h5>{{'MOBILE-APPS' | translate}} </h5>
                        <p class="text-muted mb-0">{{'MOBILE-APPS-CONTENT' | translate}}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-12 mt-5">
                <div class="features features text-center">
                    <div class="image position-relative d-inline-block">
                        <!-- <i class=""></i> -->
                        <img src="assets/images/icon/web.png" width="50" height="auto" alt="">
                    </div>

                    <div class="content mt-4">
                        <h5>{{'WEB-APPS' | translate}} </h5>
                        <p class="text-muted mb-0">{{'WEB-APPS-CONTENT' | translate}}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-12 mt-5">
                <div class="features features text-center">
                    <div class="image position-relative d-inline-block">
                        <img src="assets/images/icon/cloud.png" width="50" height="auto" alt="">
                        <!-- <i class=""></i> -->
                    </div>

                    <div class="content mt-4">
                        <h5>{{'CLOUD-APPS' | translate}} </h5>
                        <p class="text-muted mb-0">{{'CLOUD-APPS-CONTENT' | translate}}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-12 mt-5">
                <div class="features features text-center">
                    <div class="image position-relative d-inline-block">
                        <img src="assets/images/icon/distributed.png" width="50" height="auto" alt="">
                    </div>

                    <div class="content mt-4">
                        <h5>{{'DIS-SYS' | translate}} </h5>
                        <p class="text-muted mb-0">{{'DIS-SYS-CONTENT' | translate}}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-12 mt-5">
                <div class="features features text-center">
                    <div class="image position-relative d-inline-block">
                        <img src="assets/images/icon/consultation.png" width="50" height="auto" alt="">
                        <!-- <i class=""></i> -->
                    </div>

                    <div class="content mt-4">
                        <h5>{{'SOFT-CONS' | translate}} </h5>
                        <p class="text-muted mb-0">{{'SOFT-CONS-CONTENT' | translate}}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-12 mt-5">
                <div class="features features text-center">
                    <div class="image position-relative d-inline-block">
                        <img src="assets/images/icon/analysis.png" width="50" height="auto" alt="">
                    </div>

                    <div class="content mt-4">
                        <h5>{{'STRATEGY' | translate}} </h5>
                        <p class="text-muted mb-0">{{'STRATEGY-CONTENT' | translate}}</p>
                    </div>
                </div>
            </div>

        </div>
        <!-- <app-services [servicesData]="servicesData"></app-services> -->
    </div>

    <!-- <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title mb-4">{{'CLIENT-REVIEWS' | translate}}</h4>
                    <p class="text-muted para-desc mx-auto mb-0">{{'SLOGAN-P1' | translate}}
                        <span class="text-primary fw-bold">{{'COMPANY-NAME' | translate}}</span> {{'SLOGAN-P2' | translate}}</p>
                </div>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-12 mt-4">
                <app-testimonial [testimonialData]="testimonialData"></app-testimonial>
            </div>
        </div>
    </div> -->
    <!--end container-->
</section>
<!--end section-->
<!-- Feature Start -->

<!-- Project Start -->
<section class="section bg-light">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title mb-4">{{'PROJECTS' | translate}}</h4>
                    <p class="text-muted para-desc mx-auto mb-0">{{'SLOGAN-P1' | translate}} <span class="text-primary fw-bold">{{'COMPANY-NAME' | translate}}</span> {{'SLOGAN-P2' | translate}}</p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
            <div class="col-md-6 col-12 mt-4 pt-2">
                <div class="card work-container work-modern position-relative overflow-hidden shadow rounded border-0">
                    <div class="card-body p-0">
                        <img src="assets/images/servicesImgs/nuqta-medical-web.png" class="img-fluid rounded" alt="work-image">
                        <div class="overlay-work bg-dark"></div>
                        <div class="content">
                            <a class="title text-white d-block fw-bold">{{'CUBES-MEDICAL' | translate}}</a>
                            <a target="_blank" href="https://cubesmedical.cubes.ly/">
                                <small class="text-light">{{'SEE-MORE' | translate}}</small>
                            </a>
                        </div>
                        <div class="client">

                            <small class="text-light user d-block">{{'PUBLISH-DATE' | translate}}</small>
                            <small class="text-light date"><i class="mdi mdi-calendar-check"></i> 20th August, 2021</small>
                        </div>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-6 col-12 mt-4 pt-2">
                <div class="card work-container work-modern position-relative overflow-hidden shadow rounded border-0">
                    <div class="card-body p-0">
                        <img src="assets/images/servicesImgs/law-firm.png" class="img-fluid rounded" alt="work-image">
                        <div class="overlay-work bg-dark"></div>
                        <div class="content">
                            <a class="title text-white d-block fw-bold">{{'CUBES-LAWYER' | translate}}</a>
                            <a target="_blank" href="http://192.236.154.136:1012/">
                                <small class="text-light">{{'SEE-MORE' | translate}}</small>
                            </a>
                        </div>
                        <div class="client">
                            <small class="text-light user d-block"> {{'PUBLISH-DATE' | translate}}</small>
                            <small class="text-light date"><i class="mdi mdi-calendar-check"></i> 18th June, 2021</small>
                        </div>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-6 col-12 mt-4 pt-2">
                <div class="card work-container work-modern position-relative overflow-hidden shadow rounded border-0">
                    <div class="card-body p-0">
                        <img src="assets/images/servicesImgs/exchange.jpeg" class="img-fluid rounded" alt="work-image">
                        <div class="overlay-work bg-dark"></div>
                        <div class="content">
                            <a class="title text-white d-block fw-bold">{{'CUBES-EXCHANGER' | translate}}</a>
                            <small class="text-light"> {{'OFFLIE-APP' | translate}} </small>
                        </div>
                        <div class="client">
                            <small class="text-light user d-block"> {{'PUBLISH-DATE' | translate}}</small>
                            <small class="text-light date"><i class="mdi mdi-calendar-check"></i> 30th September, 2021</small>
                        </div>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-6 col-12 mt-4 pt-2">
                <div class="card work-container work-modern position-relative overflow-hidden shadow rounded border-0">
                    <div class="card-body p-0">
                        <img src="assets/images/servicesImgs/bright.PNG" class="img-fluid rounded" alt="work-image">
                        <div class="overlay-work bg-dark"></div>
                        <div class="content">
                            <a class="title text-white d-block fw-bold">{{'CUBES-BRIGHT' |translate}}</a>
                            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.cubes.cubes_market">
                                <small class="text-light">{{'SEE-MORE' | translate}}</small>

                            </a>
                        </div>
                        <div class="client">
                            <small class="text-light user d-block">{{'PUBLISH-DATE' | translate}}</small>
                            <small class="text-light date"> 10th October, 2021</small>
                        </div>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
</section>
<!--end section-->
<!-- Project End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->