<!-- Hero Start -->
<section class="bg-half-260 d-table w-100" style="background: url('assets/images/construction/bg.jpg') center center;"
    id="home">
    <div class="bg-overlay" style="opacity: 0.65;"></div>
    <div class="container">
        <div class="row mt-5 mt-md-4">
            <div class="col-12">
                <div class="title-heading">
                    <h4 class="display-4 mb-4 fw-bold text-white title-dark">Start building <br> your dream home</h4>
                    <p class="para-desc text-white-50">Launch your campaign and benefit from our expertise on designing
                        and managing conversion centered bootstrap v5 html page.</p>
                    <div class="mt-4 pt-2">
                        <a href="javascript:void(0)" class="btn btn-primary">Get Started</a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<section class="section bg-light">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="features-absolute rounded bg-white shadow">
                    <div class="row">
                        <div class="col-lg-8 col-md-12 my-4">
                            <div class="row">
                                <div class="col-md-6 col-12">
                                    <div class="d-flex features p-4">
                                        <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                                            <i class="uil uil-ruler-combined align-middle h4 mb-0"></i>
                                        </div>
                                        <div class="flex-1">
                                            <h4 class="title">Project Planning</h4>
                                            <p class="text-muted para mb-0">Composed in a pseudo-Latin language which
                                                more corresponds.</p>
                                        </div>
                                    </div>
                                </div>
                                <!--end col-->

                                <div class="col-md-6 col-12">
                                    <div class="d-flex features p-4">
                                        <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                                            <i class="uil uil-constructor align-middle h4 mb-0"></i>
                                        </div>
                                        <div class="flex-1">
                                            <h4 class="title">Qualified Specialists</h4>
                                            <p class="text-muted para mb-0">Composed in a pseudo-Latin language which
                                                more corresponds.</p>
                                        </div>
                                    </div>
                                </div>
                                <!--end col-->

                                <div class="col-md-6 col-12">
                                    <div class="d-flex features p-4">
                                        <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                                            <i class="uil uil-no-entry align-middle h4 mb-0"></i>
                                        </div>
                                        <div class="flex-1">
                                            <h4 class="title">Constant Maintenance</h4>
                                            <p class="text-muted para mb-0">Composed in a pseudo-Latin language which
                                                more corresponds.</p>
                                        </div>
                                    </div>
                                </div>
                                <!--end col-->

                                <div class="col-md-6 col-12">
                                    <div class="d-flex features p-4">
                                        <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                                            <i class="uil uil-cog align-middle h4 mb-0"></i>
                                        </div>
                                        <div class="flex-1">
                                            <h4 class="title">Warranty Service</h4>
                                            <p class="text-muted para mb-0">Composed in a pseudo-Latin language which
                                                more corresponds.</p>
                                        </div>
                                    </div>
                                </div>
                                <!--end col-->
                            </div>
                            <!--end row-->
                        </div>
                        <!--end col-->

                        <div class="col-lg-4 d-none d-lg-block position-relative">
                            <img src="assets/images/construction/serveices.png"
                                class="img-fluid mx-auto d-block construction-img" alt="">
                        </div>
                        <!--end col-->
                    </div>
                    <!--end row-->
                </div>
            </div>
            <!--end col-->

            <div class="col-12 mt-4 pt-2 text-center">
                <p class="text-muted mb-0">Our list of services does not end here. <a href="javascript:void(0)"
                        class="text-primary h6">Click here</a>, and We’ll come up with more solution for you!</p>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->

<section class="section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-6 col-12">
                <div class="position-relative">
                    <img src="assets/images/construction/about.jpg" class="rounded img-fluid mx-auto d-block" alt="">
                    <div class="play-icon">
                        <a (click)="openWindowCustomClass(content)" href="javascript:void(0);" data-type="youtube"
                            data-id="yba7hPeTSjk" class="play-btn">
                            <i class="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
                        </a>
                        <ng-template #content let-modal>
                            <div class="modal-header">
                                <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <iframe src="https://player.vimeo.com/video/287684225" height="450" width="780"
                                    frameborder="0" allowfullscreen allow="autoplay; encrypted-media"></iframe>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-7 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="ms-lg-5 ms-md-4">
                    <div class="section-title">
                        <span class="badge rounded-pill bg-soft-primary">About us</span>
                        <h4 class="title mt-3 mb-4">We are the leader <br> in the <span
                                class="text-primary">Landrick</span></h4>
                        <p class="text-muted para-desc mx-auto">Start working with <span
                                class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                            generate awareness, drive traffic, connect.</p>
                        <p class="text-muted para-desc mx-auto mb-0">The most well-known dummy text is the 'Lorem
                            Ipsum', which is said to have originated in the 16th century. Lorem Ipsum is composed in a
                            pseudo-Latin language which more or less corresponds to 'proper' Latin.</p>
                        <div class="mt-4">
                            <a href="javascript:void(0)" class="btn btn-primary">Learn More</a>
                        </div>
                    </div>
                </div>
            </div>
            <!--end col-->

        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-4 col-md-3">
                <h6 class="text-muted mb-0">Trusted by over 2,000 of the world’s leading construction companies</h6>
            </div>
            <!--end col-->

            <div class="col-lg-8 col-md-9 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="rounded shadow bg-light p-4">
                    <div class="row justify-content-center">
                        <div class="col-lg-3 col-md-3 col-6 text-center py-4">
                            <img src="assets/images/client/amazon.svg" class="avatar avatar-ex-sm" alt="">
                        </div>
                        <!--end col-->

                        <div class="col-lg-3 col-md-3 col-6 text-center py-4">
                            <img src="assets/images/client/google.svg" class="avatar avatar-ex-sm" alt="">
                        </div>
                        <!--end col-->

                        <div class="col-lg-3 col-md-3 col-6 text-center py-4">
                            <img src="assets/images/client/lenovo.svg" class="avatar avatar-ex-sm" alt="">
                        </div>
                        <!--end col-->

                        <div class="col-lg-3 col-md-3 col-6 text-center py-4">
                            <img src="assets/images/client/paypal.svg" class="avatar avatar-ex-sm" alt="">
                        </div>
                        <!--end col-->
                    </div>
                    <!--end row-->
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="section-title sticky-bar position-sticky">
                    <span class="badge rounded-pill bg-soft-primary">Projects</span>
                    <h4 class="title mt-3 mb-4">Our latest projects <br> check now dears</h4>
                    <p class="text-muted para-desc mb-0">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.</p>
                    <div class="mt-4 d-none d-md-block">
                        <a href="javascript:void(0)" class="btn btn-soft-primary">See More <i-feather name="arrow-right"
                                class="fea icon-sm"></i-feather></a>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-8 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="row">
                    <div class="col-12 filters-group-wrap">
                        <div class="filters-group">
                            <ul class="container-filter list-inline mb-0 filter-options" id="filter">
                                <li class="list-inline-item categories-name border text-dark rounded ms-2"
                                    (click)="activeCategory('all')" [ngClass]="{'active': galleryFilter ==='all'}">
                                    <a>All</a>
                                </li>
                                <li class="list-inline-item categories-name border text-dark rounded ms-2"
                                    (click)="activeCategory('offices')"
                                    [ngClass]="{'active': galleryFilter ==='offices'}"><a>Offices</a></li>
                                <li class="list-inline-item categories-name border text-dark rounded ms-2"
                                    (click)="activeCategory('buildings')"
                                    [ngClass]="{'active': galleryFilter ==='buildings'}"><a>Buildings</a></li>
                                <li class="list-inline-item categories-name border text-dark rounded ms-2"
                                    (click)="activeCategory('roads')" [ngClass]="{'active': galleryFilter ==='roads'}">
                                    <a>Roads</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!--end col-->
                </div>
                <!--end row-->

                <div id="grid" class="row">
                    <div class="col-lg-6 col-12 mt-4 pt-2 picture-item" *ngFor="let item of filterredImages">
                        <div class="card border-0 work-container work-classic shadow overflow-hidden">
                            <div class="card-body p-0">
                                <a routerLink="/portfolio-detail-one"><img src="{{ item.image}}"
                                        class="img-fluid work-image" alt=""></a>
                                <div class="content p-4">
                                    <h5 class="mb-0"><a routerLink="/portfolio-detail-one"
                                            class="text-dark title">{{item.text}}</a></h5>
                                    <h6 class="text-muted tag mb-0">{{item.sub_text}}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--end col-->
                </div>
                <!--end row-->

                <div class="row">
                    <div class="col-12 mt-4 pt-2">
                        <div class="d-block d-md-none">
                            <a href="javascript:void(0)" class="btn btn-soft-primary">See More <i-feather
                                    name="arrow-right" class="fea icon-sm"></i-feather></a>
                        </div>
                    </div>
                    <!--end col-->
                </div>
                <!--end row-->
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title mb-4 pb-2 text-center">
                    <h4 class="title mb-4">Expert Team Members</h4>
                    <p class="text-muted mx-auto para-desc mb-0">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.</p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
            <div class="col-lg-3 col-md-6 mt-4 pt-2">
                <div class="card team text-center border-0">
                    <div class="position-relative">
                        <img src="assets/images/construction/t1.jpg" class="img-fluid" alt="">
                        <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                        </ul>
                        <!--end icon-->
                    </div>
                    <div class="card-body py-3 px-0 content">
                        <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Ronny Jofra</a></h5>
                        <small class="designation text-muted">Architecture</small>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-6 mt-4 pt-2">
                <div class="card team text-center border-0">
                    <div class="position-relative">
                        <img src="assets/images/construction/t3.jpg" class="img-fluid" alt="">
                        <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                        </ul>
                        <!--end icon-->
                    </div>
                    <div class="card-body py-3 px-0 content">
                        <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Micheal Carlo</a></h5>
                        <small class="designation text-muted">Project Manager</small>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-6 mt-4 pt-2">
                <div class="card team text-center border-0">
                    <div class="position-relative">
                        <img src="assets/images/construction/t2.jpg" class="img-fluid" alt="">
                        <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                        </ul>
                        <!--end icon-->
                    </div>
                    <div class="card-body py-3 px-0 content">
                        <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Aliana Rosy</a></h5>
                        <small class="designation text-muted">Builder Advisor</small>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-6 mt-4 pt-2">
                <div class="card team text-center border-0">
                    <div class="position-relative">
                        <img src="assets/images/construction/t4.jpg" class="img-fluid" alt="">
                        <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                        </ul>
                        <!--end icon-->
                    </div>
                    <div class="card-body py-3 px-0 content">
                        <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Sofia Razaq</a></h5>
                        <small class="designation text-muted">HR Manager</small>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->

<!-- News Start -->
<section class="section bg-light">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title mb-4 pb-2 text-center">
                    <span class="badge rounded-pill bg-soft-primary">Blogs & News</span>
                    <h4 class="title mt-3 mb-4">Latest News & Articals</h4>
                    <p class="text-muted mx-auto para-desc mb-0">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.</p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
        <app-blog [blogData]="blogData"></app-blog>

        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Blog End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->