<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title"> Guides & Support </h4>

          <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                <li class="breadcrumb-item"><a href="javascript:void(0)">Help Center</a></li>
                <li class="breadcrumb-item active" aria-current="page">Guides</li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Start Section -->
<section class="section">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-6 col-12">
        <h5>Getting started</h5>
        <ul class="list-unstyled mt-4 mb-0">
          <li class="mt-2"><a href="javascript:void(0)" class="text-muted"><i
                class="mdi mdi-arrow-right text-primary me-2"></i>Deciding to purchase</a></li>
          <li class="mt-2"><a href="javascript:void(0)" class="text-muted"><i
                class="mdi mdi-arrow-right text-primary me-2"></i>List your space</a></li>
          <li class="mt-2"><a href="javascript:void(0)" class="text-muted"><i
                class="mdi mdi-arrow-right text-primary me-2"></i>Landing an experience or adventure</a></li>
          <li class="mt-2"><a href="javascript:void(0)" class="text-muted"><i
                class="mdi mdi-arrow-right text-primary me-2"></i>Top uses questions</a></li>
        </ul>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <h5>Your calendar</h5>
        <ul class="list-unstyled mt-4 mb-0">
          <li class="mt-2"><a href="javascript:void(0)" class="text-muted"><i
                class="mdi mdi-arrow-right text-primary me-2"></i>Pricing & availability</a></li>
          <li class="mt-2"><a href="javascript:void(0)" class="text-muted"><i
                class="mdi mdi-arrow-right text-primary me-2"></i>Booking settings</a></li>
          <li class="mt-2"><a href="javascript:void(0)" class="text-muted"><i
                class="mdi mdi-arrow-right text-primary me-2"></i>Responding to enquiries & requests</a></li>
          <li class="mt-2"><a href="javascript:void(0)" class="text-muted"><i
                class="mdi mdi-arrow-right text-primary me-2"></i>Snoozing or deactivating your listing</a></li>
        </ul>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0 pt-2">
        <h5>Your listings</h5>
        <ul class="list-unstyled mt-4 mb-0">
          <li class="mt-2"><a href="javascript:void(0)" class="text-muted"><i
                class="mdi mdi-arrow-right text-primary me-2"></i>Updating your listing</a></li>
          <li class="mt-2"><a href="javascript:void(0)" class="text-muted"><i
                class="mdi mdi-arrow-right text-primary me-2"></i>Neighbourhoods</a></li>
          <li class="mt-2"><a href="javascript:void(0)" class="text-muted"><i
                class="mdi mdi-arrow-right text-primary me-2"></i>Listing photos & photography</a></li>
          <li class="mt-2"><a href="javascript:void(0)" class="text-muted"><i
                class="mdi mdi-arrow-right text-primary me-2"></i>Landrick Plus</a></li>
          <li class="mt-2"><a href="javascript:void(0)" class="text-muted"><i
                class="mdi mdi-arrow-right text-primary me-2"></i>API-connected software</a></li>
        </ul>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <h5>How payouts work</h5>
        <ul class="list-unstyled mt-4 mb-0">
          <li class="mt-2"><a href="javascript:void(0)" class="text-muted"><i
                class="mdi mdi-arrow-right text-primary me-2"></i>Getting paid</a></li>
          <li class="mt-2"><a href="javascript:void(0)" class="text-muted"><i
                class="mdi mdi-arrow-right text-primary me-2"></i>Adding payout info</a></li>
          <li class="mt-2"><a href="javascript:void(0)" class="text-muted"><i
                class="mdi mdi-arrow-right text-primary me-2"></i>Your payout status</a></li>
          <li class="mt-2"><a href="javascript:void(0)" class="text-muted"><i
                class="mdi mdi-arrow-right text-primary me-2"></i>Donations</a></li>
          <li class="mt-2"><a href="javascript:void(0)" class="text-muted"><i
                class="mdi mdi-arrow-right text-primary me-2"></i>Taxes</a></li>
        </ul>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <h5>Your reservations</h5>
        <ul class="list-unstyled mt-4 mb-0">
          <li class="mt-2"><a href="javascript:void(0)" class="text-muted"><i
                class="mdi mdi-arrow-right text-primary me-2"></i>Landrick safely</a></li>
          <li class="mt-2"><a href="javascript:void(0)" class="text-muted"><i
                class="mdi mdi-arrow-right text-primary me-2"></i>Landrick Experiences and Adventures</a></li>
          <li class="mt-2"><a href="javascript:void(0)" class="text-muted"><i
                class="mdi mdi-arrow-right text-primary me-2"></i>Changing a reservation</a></li>
          <li class="mt-2"><a href="javascript:void(0)" class="text-muted"><i
                class="mdi mdi-arrow-right text-primary me-2"></i>Cancelling a reservation</a></li>
          <li class="mt-2"><a href="javascript:void(0)" class="text-muted"><i
                class="mdi mdi-arrow-right text-primary me-2"></i>Long-term reservations</a></li>
        </ul>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <h5>Reservation help</h5>
        <ul class="list-unstyled mt-4 mb-0">
          <li class="mt-2"><a href="javascript:void(0)" class="text-muted"><i
                class="mdi mdi-arrow-right text-primary me-2"></i>Help with a reservation or guest</a></li>
          <li class="mt-2"><a href="javascript:void(0)" class="text-muted"><i
                class="mdi mdi-arrow-right text-primary me-2"></i>Guest cancellations</a></li>
        </ul>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <h5>Your account</h5>
        <ul class="list-unstyled mt-4 mb-0">
          <li class="mt-2"><a href="javascript:void(0)" class="text-muted"><i
                class="mdi mdi-arrow-right text-primary me-2"></i>Your profile</a></li>
          <li class="mt-2"><a href="javascript:void(0)" class="text-muted"><i
                class="mdi mdi-arrow-right text-primary me-2"></i>Account security</a></li>
          <li class="mt-2"><a href="javascript:void(0)" class="text-muted"><i
                class="mdi mdi-arrow-right text-primary me-2"></i>Identification & verifications</a></li>
          <li class="mt-2"><a href="javascript:void(0)" class="text-muted"><i
                class="mdi mdi-arrow-right text-primary me-2"></i>Reviews</a></li>
          <li class="mt-2"><a href="javascript:void(0)" class="text-muted"><i
                class="mdi mdi-arrow-right text-primary me-2"></i>Superhost status</a></li>
        </ul>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-12">
        <div class="d-flex align-items-center features feature-clean shadow rounded p-4">
          <div class="icons text-primary text-center">
            <i class="uil uil-envelope-check d-block rounded h3 mb-0"></i>
          </div>
          <div class="flex-1 content ms-4">
            <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Get in Touch !</a></h5>
            <p class="text-muted mb-0">This is required when, for text is not yet available.</p>
            <div class="mt-2">
              <a href="javascript:void(0)" class="btn btn-sm btn-soft-primary">Submit a Request</a>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="d-flex align-items-center features feature-clean shadow rounded p-4">
          <div class="icons text-primary text-center">
            <i class="uil uil-webcam d-block rounded h3 mb-0"></i>
          </div>
          <div class="flex-1 content ms-4">
            <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Start a Meeting</a></h5>
            <p class="text-muted mb-0">This is required when, for text is not yet available.</p>
            <div class="mt-2">
              <a href="javascript:void(0)" class="btn btn-sm btn-soft-primary">Start Video Chat</a>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Section -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->