<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="page-next-level">
                    <h4 class="title"> New Trends in SEO </h4>
                    <ul class="list-unstyled mt-4">
                        <li class="list-inline-item h6 user text-muted me-2"> <span class="text-dark">Client :</span>
                            Calvin Carlo</li>
                        <li class="list-inline-item h6 date text-muted"> <span class="text-dark">Date :</span> 23th Sep,
                            2019</li>
                    </ul>
                    <div class="page-next">
                        <nav aria-label="breadcrumb" class="d-inline-block">
                            <ul class="breadcrumb bg-white rounded shadow mb-0">
                                <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                                <li class="breadcrumb-item"><a href="javascript:void(0)">Page</a></li>
                                <li class="breadcrumb-item"><a href="javascript:void(0)">Work</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Work Detail</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->

<!-- Start Work Detail -->
<section class="section">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
                <div class="work-detail">
                    <h4 class="title mb-3">Introduction of Project :</h4>
                    <p class="description text-muted">Due to its widespread use as filler text for layouts,
                        non-readability is of great importance: human perception is tuned to recognize certain patterns
                        and repetitions in texts.</p>
                    <p class="description text-muted mb-0">If the distribution of letters and 'words' is random, the
                        reader will not be distracted from making a neutral judgement on the visual impact and
                        readability of the typefaces (typography), or the distribution of text on the page (layout or
                        type area). For this reason, dummy text usually consists of a more or less random series of
                        words or syllables.</p>

                    <div class="row">
                        <div class="col-lg-6 mt-4 pt-2">
                            <img src="assets/images/work/1.jpg" class="img-fluid rounded" alt="">
                        </div>
                        <!--end col-->

                        <div class="col-lg-6 mt-4 pt-2">
                            <img src="assets/images/work/3.jpg" class="img-fluid rounded" alt="">
                        </div>
                        <!--end col-->
                    </div>
                    <!--end row-->

                    <blockquote class="blockquote text-center mt-4 mb-0 p-md-4">
                        <span class="h3 text-muted"><i class="mdi mdi-format-quote-open"></i></span>
                        <p class="text-muted mb-0 h5 fw-normal fst-italic"> There are many variations of passages of
                            Lorem Ipsum available, but the majority have suffered alteration in some form, by injected
                            humour, or randomised words which don't look even slightly believable.</p>
                        <span class="h3 text-muted"><i class="mdi mdi-format-quote-open"></i></span>
                    </blockquote>
                    <p class="description text-muted mt-4 mt-sm-0 mb-0">If the distribution of letters and 'words' is
                        random, the reader will not be distracted from making a neutral judgement on the visual impact
                        and readability of the typefaces (typography), or the distribution of text on the page (layout
                        or type area). For this reason, dummy text usually consists of a more or less random series of
                        words or syllables.</p>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 order-1 order-md-2">
                <div class="sticky-bar work-detail p-4 rounded shadow">
                    <h4 class="title pb-3 border-bottom">Project Info :</h4>

                    <ul class="list-unstyled mb-0">
                        <li class="mt-3">
                            <b>Client :</b>
                            <span>Calvin Carlo</span>
                        </li>
                        <li class="mt-3">
                            <b>Category :</b>
                            <span>Web Design</span>
                        </li>
                        <li class="mt-3">
                            <b>Date :</b>
                            <span>26rd Oct, 2019</span>
                        </li>
                        <li class="mt-3">
                            <b>Website :</b>
                            <span>www.yourdomain.com</span>
                        </li>
                        <li class="mt-3">
                            <b>Location :</b>
                            <span>3/2/64 Mongus Street, UK</span>
                        </li>
                    </ul>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End Work Detail -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->