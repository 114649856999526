<owl-carousel-o id="customer-testi" [options]="customOptions">
    <ng-container *ngFor="let data of testimonialData">
        <ng-template carouselSlide>
            <div class="tiny-slide">
                <div class="d-flex client-testi m-2">
                    <img src="{{data.profile}}" class="avatar avatar-small client-image rounded shadow" alt="">
                    <div class="flex-1 content p-3 shadow rounded bg-white position-relative">
                        <ul class="list-unstyled mb-0">
                            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                        </ul>
                        <p class="text-muted mt-2">{{data.message}}</p>
                        <h6 class="text-primary">- {{data.name}} <small class="text-muted">{{data.designation}}</small>
                        </h6>
                    </div>
                </div>
            </div>
        </ng-template>
    </ng-container>
</owl-carousel-o>