import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  lastLocale: string = "" ;
  constructor(
    private translateService: TranslateService
  ) { }

  changeAppDirection(local: string) {
    return local !== "ar"
      ? document.getElementsByTagName("html")[0].setAttribute("dir", "ltr")
      : document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
  }

  changeHtmlLang(local: string) {
    return local !== "ar"
      ? document.getElementsByTagName("html")[0].setAttribute("lang", "en")
      : document.getElementsByTagName("html")[0].setAttribute("lang", "ar");
  }
  initAppUrl(lang = '') {
    this.changeAppDirection(lang);
    this.changeHtmlLang(lang);
  }
  getLanguage() {
    return localStorage.getItem('language');
  }
  changeAppLanguage(local: string) {
    this.lastLocale = local;
    this.translateService.use(local);
    this.changeAppDirection(local);
    this.changeHtmlLang(local);
  }
}
