import { ProductsService } from './../../../services/products.service';
import { Product } from './../../../interfaces/product';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
  p: number = 1;
  en: boolean = false
  lang: string = ''
  allProducts: Product[]

  constructor(private _prodServ: ProductsService) { 
  }

  ngOnInit(): void {
    this.lang = localStorage.getItem("lang")
    if(this.lang == 'en') {
      this.en = true
      this.gatAllProductsEn();
    }
    else {
      this.gatAllProductsAr();
    }
  }

  gatAllProductsAr() {
    return this._prodServ.gatAllProductsAr().subscribe(
      (res: any)=> {
        console.log(res);
        this.allProducts = res;
      },
      (error)=> {
        console.log(error);       
      }
    )
  }


  gatAllProductsEn() {
    return this._prodServ.gatAllProductsEn().subscribe(
      (res: any)=> {
        console.log(res);
        this.allProducts = res;
      },
      (error)=> {
        console.log(error);       
      }
    )
  }
}
