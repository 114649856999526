<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title"> Pricing </h4>
          <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                <li class="breadcrumb-item"><a href="javascript:void(0)">Page</a></li>
                <li class="breadcrumb-item active" aria-current="page">Pricing</li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Price Start -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Pricing #1</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <app-simple-pricing [simple_pricingData]="simple_pricingData"></app-simple-pricing>
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Pricing #2</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row align-items-center">
      <div class="col-12 mt-4 pt-2">
        <div class="text-center">
          <ul ngbNav #nav="ngbNav"
            class="nav nav-pills rounded-pill justify-content-center d-inline-block border py-1 px-2" id="pills-tab"
            role="tablist">
            <li ngbNavItem class="nav-item d-inline-block">
              <a ngbNavLink class="nav-link px-3 rounded-pill">
                Monthly
              </a>
              <ng-template ngbNavContent>
                <app-pricing [pricingData]="monthlyData"></app-pricing>
              </ng-template>
            </li>
            <li ngbNavItem class="nav-item d-inline-block">
              <a ngbNavLink class="nav-link px-3 rounded-pill">
                Yearly <span class="badge rounded-pill bg-success">15% Off </span>
              </a>
              <ng-template ngbNavContent>
                <app-pricing [pricingData]="yearlyData"></app-pricing>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav" class="col-12"></div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
  <!-- Price End -->

  <!-- Testi Start -->
  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Client Reviews</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row justify-content-center">
      <div class="col-lg-12 mt-4">
        <app-testimonial [testimonialData]="testimonialData"></app-testimonial>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Testi End -->

<!-- FAQ n Contact Start -->
<section class="section bg-light">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-12">
        <div class="d-flex">
          <i-feather name="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i-feather>
          <div class="flex-1">
            <h5 class="mt-0">How our <span class="text-primary">Landrick</span> work ?</h5>
            <p class="answer text-muted mb-0">Due to its widespread use as filler text for layouts, non-readability is
              of great importance: human perception is tuned to recognize certain patterns and repetitions in texts.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="d-flex">
          <i-feather name="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i-feather>
          <div class="flex-1">
            <h5 class="mt-0"> What is the main process open account ?</h5>
            <p class="answer text-muted mb-0">If the distribution of letters and 'words' is random, the reader will not
              be distracted from making a neutral judgement on the visual impact</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-6 col-12 mt-4 pt-2">
        <div class="d-flex">
          <i-feather name="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i-feather>
          <div class="flex-1">
            <h5 class="mt-0"> How to make unlimited data entry ?</h5>
            <p class="answer text-muted mb-0">Furthermore, it is advantageous when the dummy text is relatively
              realistic so that the layout impression of the final publication is not compromised.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-6 col-12 mt-4 pt-2">
        <div class="d-flex">
          <i-feather name="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i-feather>
          <div class="flex-1">
            <h5 class="mt-0"> Is <span class="text-primary">Landrick</span> safer to use with my account ?</h5>
            <p class="answer text-muted mb-0">The most well-known dummy text is the 'Lorem Ipsum', which is said to have
              originated in the 16th century. Lorem Ipsum is composed in a pseudo-Latin language which more or less
              corresponds to 'proper' Latin.</p>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row mt-md-5 pt-md-3 mt-4 pt-2 mt-sm-0 pt-sm-0 justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title mb-4">Have Question ? Get in touch!</h4>
          <p class="text-muted para-desc mx-auto">Start working with <span class="text-primary fw-bold">Landrick</span>
            that can provide everything you need to generate awareness, drive traffic, connect.</p>
          <div class="mt-4 pt-2">
            <a routerLink="/page-contact-two" class="btn btn-primary">Contact us <i class="mdi mdi-arrow-right"></i></a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- FAQ n Contact End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->