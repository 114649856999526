import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})

/***
 * Footer Component
 */
export class FooterComponent implements OnInit {

  @Input() footerVariant: string;
  @Input() hideFooter: boolean;
  en: boolean = false
  lang: string

  //Get Year
  year = new Date().getFullYear()

  constructor() { }

  ngOnInit(): void {
    this.lang = localStorage.getItem("lang");
    if(this.lang === "en") {
      this.en = true
    }
  }

}
