<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home" style="background: url('assets/images/contact-detail.jpg') center center;">
    <div class="bg-overlay bg-overlay-white"></div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="page-next-level">
                    <h4 class="title">{{'CONTACT' | translate}}</h4>
                    <div class="page-next">
                        <nav aria-label="breadcrumb" class="d-inline-block">
                            <ul class="breadcrumb bg-white rounded shadow mb-0">
                                <li class="breadcrumb-item"><a routerLink="/index">{{'COMPANY-NAME' | translate}}</a></li>
                                <li class="breadcrumb-item active" aria-current="page">{{'CONTACT' | translate}}</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
    </div>
</div>
<!--Shape End-->

<!-- Start Contact -->
<section class="section">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6  mt-4 pt-2">
                <div class="card shadow rounded border-0">
                    <div class="card-body py-5">
                        <h4 class="card-title">{{'GET-IN-TOUCH' | translate}} !</h4>
                        <div class="custom-form mt-3">
                            <form method="post" name="myForm" onsubmit="return validateForm()">
                                <p id="error-msg" class="mb-0"></p>
                                <div id="simple-msg"></div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="mb-3">
                                            <label class="form-label">{{'CLIENT-NAME' | translate}} <span class="text-danger">*</span></label>
                                            <div class="form-icon position-relative">
                                                <i-feather name="user" class="fea icon-sm icons"></i-feather>
                                                <input name="name" id="name" type="text" class="form-control ps-5" placeholder="{{'NAME' | translate}} :">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="mb-3">
                                            <label class="form-label">{{'EMAIL' | translate}} <span class="text-danger">*</span></label>
                                            <div class="form-icon position-relative">
                                                <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                                                <input name="email" id="email" type="email" class="form-control ps-5" placeholder="{{'EMAIL' | translate}} :">
                                            </div>
                                        </div>
                                    </div>
                                    <!--end col-->

                                    <div class="col-12">
                                        <div class="mb-3">
                                            <label class="form-label">{{'SUBJECT' | translate}}</label>
                                            <div class="form-icon position-relative">
                                                <i-feather name="book" class="fea icon-sm icons"></i-feather>
                                                <input name="subject" id="subject" class="form-control ps-5" placeholder="{{'SUBJECT' | translate}} :">
                                            </div>
                                        </div>
                                    </div>
                                    <!--end col-->

                                    <div class="col-12">
                                        <div class="mb-3">
                                            <label class="form-label">{{'MESSAGE' | translate}} <span class="text-danger">*</span></label>
                                            <div class="form-icon position-relative">
                                                <i-feather name="message-circle" class="fea icon-sm icons clearfix"></i-feather>
                                                <textarea name="comments" id="comments" rows="4" class="form-control ps-5" placeholder="{{'MESSAGE' | translate}} :"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="d-grid">
                                            <button type="submit" id="submit" name="send" class="btn btn-primary">{{'SEND-MESSAGE' | translate}}</button>
                                        </div>
                                    </div>
                                    <!--end col-->
                                </div>
                                <!--end row-->
                            </form>
                        </div>
                        <!--end custom-form-->
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-8 col-md-6 ps-md-3 pr-md-3 mt-4 pt-2">
                <div class="card map map-height-two rounded map-gray border-0">
                    <div class="card-body p-0">

                        <iframe src="https://www.google.com/maps/d/embed?mid=1HKofRQH6um4hVcAgEt9iYcHn3lKcMNN3" style="border:0" class="rounded" allowfullscreen=""></iframe>
                        <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2587.2661949688863!2d29.95811813825738!3d31.236325404651478!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14f5c55e717515b9%3A0xd69d285d9a8c4e00!2z2LTYsdmD2Kkg2YPZitmI2KjYsyDZhNiq2YLZhtmK2Kkg2KfZhNmF2LnZhNmI2YXYp9iq!5e0!3m2!1sen!2seg!4v1632393541246!5m2!1sen!2seg"
                            style="border:0" class="rounded" allowfullscreen=""></iframe> -->
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row">
            <div class="col-md-4">
                <div class="card border-0 text-center features feature-clean">
                    <div class="icons text-primary text-center mx-auto">
                        <i class="uil uil-phone d-block rounded h3 mb-0"></i>
                    </div>
                    <div class="content mt-3">
                        <h5 class="fw-bold">{{'CONTACT' | translate}}</h5>
                        <!-- <p class="text-muted">Call us now !</p> -->
                        <ul class="list-inline mb-0 mt-3">
                            <li class="list-inline-item me-2">
                                {{'BENGHAZI' |translate}} :
                            </li>
                            <a href="tel:091035030" class="text-primary">091035030</a>
                            <span> - </span>
                            <a href="tel:0915688883" class="text-primary">0915688883</a>
                            <br>
                            <li class="list-inline-item me-2">
                                {{'TRIPOLI' |translate}} :
                            </li>
                            <a href="tel:‎0910350306" class="text-primary">‎0910350306</a>
                            <span> - </span>
                            <a href="tel:0915688881" class="text-primary">0915688881</a>
                            <br>
                            <li class="list-inline-item me-2">
                                {{'MISRATA' |translate}} :
                            </li>
                            <a href="tel:‎0915688811" class="text-primary">‎0915688811</a>
                            <br>
                            <li class="list-inline-item me-2">
                                {{'ALEX' |translate}} :
                            </li>
                            <a href="tel:‎00201102571929" class="text-primary">‎00201102571929</a>
                        </ul>


                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="card border-0 text-center features feature-clean">
                    <div class="icons text-primary text-center mx-auto">
                        <i class="uil uil-envelope d-block rounded h3 mb-0"></i>
                    </div>
                    <div class="content mt-3">
                        <h5 class="fw-bold">{{'EMAIL' | translate}}</h5>
                        <!-- <p class="text-muted">Start working with Landrick that can provide everything</p> -->
                        <a href="mailto:Info@cubes.ly" class="text-primary">Info@cubes.ly</a>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="card border-0 text-center features feature-clean">
                    <div class="icons text-primary text-center mx-auto">
                        <i class="uil uil-map-marker d-block rounded h3 mb-0"></i>
                    </div>
                    <div class="content mt-3">
                        <h5 class="fw-bold">{{'LOC' | translate}}</h5>
                        <ul class="list-inline mb-0 mt-3">
                            <li class="list-inline-item me-2">
                                <p class="text-muted"> {{'BENGHAZI' | translate}} - {{'BENGHAZI-ADDRESS' | translate}}
                                </p>
                            </li>
                            <li class="list-inline-item me-2">
                                <p class="text-muted"> {{'TRIPOLI' | translate}} - {{'TRIPOLI-ADDRESS' | translate}}
                                </p>
                            </li>
                            <li class="list-inline-item me-2">
                                <p class="text-muted"> {{'MISRATA' | translate}} - {{'MISRATA-ADDRESS' | translate}}
                                </p>
                            </li>
                            <li class="list-inline-item me-2">
                                <p class="text-muted"> {{'ALEX' | translate}} - {{'ALEX-ADDRESS' | translate}}
                                </p>
                            </li>
                        </ul>
                        <a (click)="mapView(content)" href="javascript:void(0);" class="video-play-icon text-primary">View on Google map</a>
                        <ng-template #content let-modal>
                            <div class="modal-header">
                                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
                            </div>
                            <div class="modal-body">

                                <iframe src="https://www.google.com/maps/d/embed?mid=1HKofRQH6um4hVcAgEt9iYcHn3lKcMNN3" height="450" width="750">
                </iframe>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End contact -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->