import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {
  navClass = 'nav-light';
  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

    /**
  * Open modal for show the video
  * @param content content of modal
  */
     openWindowCustomClass(content) {
      this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
    }

}
