<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="page-next-level">
                    <h4 class="title"> {{'PRIVACY' | translate}} </h4>
                    <ul class="list-unstyled mt-4">
                        <li class="list-inline-item h6 date text-muted"> <span class="text-dark">Last Revised :</span> 1st August, 2021
                        </li>
                    </ul>
                    <div class="page-next">
                        <nav aria-label="breadcrumb" class="d-inline-block">
                            <ul class="breadcrumb bg-white rounded shadow mb-0">
                                <li class="breadcrumb-item"><a routerLink="/index">{{'COMPANY-NAME' | translate}}</a></li>
                                <li class="breadcrumb-item"><a href="javascript:void(0)">{{'ABOUT-US' | translate}}</a></li>
                                <li class="breadcrumb-item active" aria-current="page">{{'PRIVACY' | translate}}</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
    </div>
</div>
<!--Shape End-->

<!-- Start Privacy -->
<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-9">
                <div class="card shadow rounded border-0">
                    <div class="card-body">
                        <h5 class="card-title">{{'INTRO' | translate}}</h5>
                        <p class="text-muted">{{'PRIVACY-POLICY-OVERVIEW' | translate}}</p>


                        <h5 class="card-title">{{'PRIVACY-POLICY-INFO-TITLE' | translate}}</h5>
                        <ul class="list-unstyled text-muted">
                            <li>
                                <i-feather *ngIf="en; else ar" name="arrow-right" class="fea icon-sm me-2"></i-feather>
                                <ng-template #ar>
                                    <i-feather name="arrow-left" class="fea icon-sm me-2"></i-feather>
                                </ng-template> {{'PRIVACY-POLICY-INFO-1' | translate}}
                            </li>
                            <li>
                                <i-feather *ngIf="en; else ar" name="arrow-right" class="fea icon-sm me-2"></i-feather>
                                <ng-template #ar>
                                    <i-feather name="arrow-left" class="fea icon-sm me-2"></i-feather>
                                </ng-template> {{'PRIVACY-POLICY-INFO-2' | translate}}
                            </li>
                            <li>
                                <i-feather *ngIf="en; else ar" name="arrow-right" class="fea icon-sm me-2"></i-feather>
                                <ng-template #ar>
                                    <i-feather name="arrow-left" class="fea icon-sm me-2"></i-feather>
                                </ng-template> {{'PRIVACY-POLICY-INFO-3' | translate}}
                            </li>
                            <li>

                                <i-feather *ngIf="en; else ar" name="arrow-right" class="fea icon-sm me-2"></i-feather>
                                <ng-template #ar>
                                    <i-feather name="arrow-left" class="fea icon-sm me-2"></i-feather>
                                </ng-template>
                                {{'PRIVACY-POLICY-INFO-4' | translate}}
                            </li>
                        </ul>

                        <h5 class="card-title">{{'PRIVACY-POLICY-INFO-PROVIDE-TITLE' | translate}}</h5>
                        <p class="text-muted">{{'PRIVACY-POLICY-INFO-PROVIDE' | translate}}</p>

                        <!-- <a href="javascript:window.print()" class="btn btn-soft-primary d-print-none">Print</a> -->
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End Privacy -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->