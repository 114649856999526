<!-- Hero Start -->
<section class="bg-half-170 d-table w-100 home-wrapper overflow-hidden" id="home">
    <div class="container">
        <div class="row mt-5 align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="title-heading">
                    <span class="badge rounded-pill bg-soft-primary"><b>Medical</b></span>
                    <h4 class="heading my-3">We Take Care <br> of Your <span class="text-primary">Health</span></h4>
                    <p class="para-desc text-muted mb-0">Launch your campaign and benefit from our expertise on
                        designing and managing conversion centered bootstrap v5 html page.</p>

                    <div class="mt-3">
                        <a href="javascript:void(0)" class="btn btn-primary me-2 mt-2">Find Doctors</a>
                        <a href="javascript:void(0)" class="btn btn-soft-primary mt-2">Book an Appointment</a>
                    </div>

                    <p class="text-muted mb-0 mt-3">Don't have an account yet? <a href="javascript:void(0)"
                            class="text-primary ms-2 h6 mb-0">Signup <i-feather name="arrow-right" class="fea icon-sm">
                            </i-feather></a></p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="ms-lg-4">
                    <img src="assets/images/illustrator/doctors-cuate.svg" class="img-fluid" alt="">
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Start -->
<section class="section bg-light">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-12">
                <div class="card features fea-primary rounded p-4 bg-white position-relative overflow-hidden border-0">
                    <span class="h1 icon2 text-primary">
                        <i class="uil uil-newspaper"></i>
                    </span>
                    <div class="card-body p-0 content">
                        <h5>Appointment</h5>
                        <p class="para text-muted mb-0">It is a long established fact that a reader layout.</p>

                        <a href="javascript:void(0)" class="btn btn-pills btn-soft-success mt-4" data-bs-toggle="modal"
                            data-bs-target="#appointment-request">Request</a>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="card features fea-primary rounded p-4 bg-white position-relative overflow-hidden border-0">
                    <span class="h1 icon2 text-primary">
                        <i class="uil uil-clock"></i>
                    </span>
                    <div class="card-body p-0 content">
                        <h5>Opening Hour</h5>

                        <h6 class="mb-0">Monday - Friday</h6>
                        <p class="para text-muted">7AM to 11 PM</p>

                        <h6 class="mb-0">Address:</h6>
                        <p class="para text-muted mb-0">C/54 Northwest Freeway</p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-6 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div class="card features fea-primary rounded p-4 bg-white position-relative overflow-hidden border-0">
                    <span class="h1 icon2 text-primary">
                        <i class="uil uil-user-md"></i>
                    </span>
                    <div class="card-body p-0 content">
                        <h5>Find Doctors</h5>
                        <p class="para text-muted mb-0">It is a long established fact that a reader layout.</p>

                        <a href="javascript:void(0)" class="btn btn-pills btn-soft-success mt-4">Doctors</a>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-6 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div class="card features fea-primary rounded p-4 bg-white position-relative overflow-hidden border-0">
                    <span class="h1 icon2 text-primary">
                        <i class="uil uil-map-marker"></i>
                    </span>
                    <div class="card-body p-0 content">
                        <h5>Find Locations</h5>
                        <p class="para text-muted mb-0">It is a long established fact that a reader layout.</p>

                        <a href="javascript:void(0)" class="btn btn-pills btn-soft-success mt-4">Locations</a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End -->

<!-- Start -->
<section class="section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-6">
                <div class="position-relative">
                    <img src="assets/images/medical/about.jpg" class="rounded img-fluid mx-auto d-block shadow rounded"
                        alt="">
                    <div class="play-icon">
                        <a (click)="openWindowCustomClass(content)" href="javascript:void(0);" data-type="youtube"
                            data-id="yba7hPeTSjk" class="play-btn">
                            <i class="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
                        </a>
                        <ng-template #content let-modal>
                            <div class="modal-header">
                                <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <iframe src="https://player.vimeo.com/video/287684225" height="450" width="780"
                                    frameborder="0" allowfullscreen allow="autoplay; encrypted-media"></iframe>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="section-title ms-lg-5">
                    <span class="badge rounded-pill bg-soft-primary">About us</span>
                    <h4 class="title mt-3 mb-4">A great place to receive care</h4>
                    <p class="text-muted para-desc">If the distribution of letters and 'words' is random, the reader
                        will not be distracted from making a neutral judgement on the visual impact and readability of
                        the typefaces (typography), or the distribution of text on the page (layout or type area).</p>
                    <p class="text-muted para-desc mb-0">For this reason, dummy text usually consists of a more or less
                        random series of words or syllables.</p>

                    <div class="mt-4">
                        <a href="javascript:void(0)" class="btn btn-primary">More Services</a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row align-items-end mb-4 pb-2">
            <div class="col-md-8">
                <div class="section-title text-center text-md-start">
                    <h6 class="text-primary">Services</h6>
                    <h4 class="title mb-4">Our Medical Services</h4>
                    <p class="text-muted mb-0 para-desc">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.</p>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-4 mt-4 mt-sm-0 d-none d-md-block">
                <div class="text-center text-md-end">
                    <a href="javascript:void(0)" class="text-primary h6">See More <i-feather name="arrow-right"
                            class="fea icon-sm"></i-feather></a>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
            <div class="d-flex">
                <ul ngbNav #nav="ngbNav" [(activeId)]="active"
                    class="nav nav-pills flex-column bg-white rounded shadow p-3 mb-0 col-md-4" orientation="vertical">
                    <li ngbNavItem="top" class="nav-item">
                        <a ngbNavLink>
                            <div class="text-start py-1 px-2">
                                <h6 class="mb-0">Dental Service</h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade bg-white show active p-4 rounded shadow" id="dental"
                                role="tabpanel" aria-labelledby="dentalcare">
                                <img src="assets/images/medical/dental.jpg" class="img-fluid rounded shadow" alt="">
                                <div class="mt-4">
                                    <h5>Dental Service</h5>
                                    <p class="text-muted my-3">This is required when, for example, the final text is not
                                        yet available. Dummy text is also known as 'fill text'. It is said that song
                                        composers of the past used dummy texts as lyrics.</p>
                                    <a href="javascript:void(0)" class="text-primary">Learn More <i-feather
                                            name="arrow-right" class="fea icon-sm"></i-feather></a>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem="middle" class="nav-item mt-2">
                        <a ngbNavLink>
                            <div class="text-start py-1 px-2">
                                <h6 class="mb-0">Cardiography Service</h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade bg-white show active p-4 rounded shadow" id="dental"
                                role="tabpanel" aria-labelledby="dentalcare">
                                <img src="assets/images/medical/cardio.jpg" class="img-fluid rounded shadow" alt="">
                                <div class="mt-4">
                                    <h5>Cardiography Service</h5>
                                    <p class="text-muted my-3">This is required when, for example, the final text is not
                                        yet available. Dummy text is also known as 'fill text'. It is said that song
                                        composers of the past used dummy texts as lyrics.</p>
                                    <a href="javascript:void(0)" class="text-primary">Learn More <i-feather
                                            name="arrow-right" class="fea icon-sm"></i-feather></a>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem="bottom" class="nav-item mt-2">
                        <a ngbNavLink>
                            <div class="text-start py-1 px-2">
                                <h6 class="mb-0">Eye Care Service</h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade bg-white show active p-4 rounded shadow" id="dental"
                                role="tabpanel" aria-labelledby="dentalcare">
                                <img src="assets/images/medical/eyecare.jpg" class="img-fluid rounded shadow" alt="">
                                <div class="mt-4">
                                    <h5>Eye Care</h5>
                                    <p class="text-muted my-3">This is required when, for example, the final text is not
                                        yet available. Dummy text is also known as 'fill text'. It is said that song
                                        composers of the past used dummy texts as lyrics.</p>
                                    <a href="javascript:void(0)" class="text-primary">Learn More <i-feather
                                            name="arrow-right" class="fea icon-sm"></i-feather></a>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem="general" class="nav-item mt-2">
                        <a ngbNavLink>
                            <div class="text-start py-1 px-2">
                                <h6 class="mb-0">General Checkup</h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade bg-white show active p-4 rounded shadow" id="dental"
                                role="tabpanel" aria-labelledby="dentalcare">
                                <img src="assets/images/medical/general.jpg" class="img-fluid rounded shadow" alt="">
                                <div class="mt-4">
                                    <h5>General Body Checkup</h5>
                                    <p class="text-muted my-3">This is required when, for example, the final text is not
                                        yet available. Dummy text is also known as 'fill text'. It is said that song
                                        composers of the past used dummy texts as lyrics.</p>
                                    <a href="javascript:void(0)" class="text-primary">Learn More <i-feather
                                            name="arrow-right" class="fea icon-sm"></i-feather></a>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem="medicine" class="nav-item mt-2">
                        <a ngbNavLink>
                            <div class="text-start py-1 px-2">
                                <h6 class="mb-0">Medicine Service</h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade bg-white show active p-4 rounded shadow" id="dental"
                                role="tabpanel" aria-labelledby="dentalcare">
                                <img src="assets/images/medical/medicine.jpg" class="img-fluid rounded shadow" alt="">
                                <div class="mt-4">
                                    <h5>Medicine Services</h5>
                                    <p class="text-muted my-3">This is required when, for example, the final text is not
                                        yet available. Dummy text is also known as 'fill text'. It is said that song
                                        composers of the past used dummy texts as lyrics.</p>
                                    <a href="javascript:void(0)" class="text-primary">Learn More <i-feather
                                            name="arrow-right" class="fea icon-sm"></i-feather></a>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem="orthopadic" class="nav-item mt-2">
                        <a ngbNavLink>
                            <div class="text-start py-1 px-2">
                                <h6 class="mb-0">Orthopadic</h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade bg-white show active p-4 rounded shadow" id="dental"
                                role="tabpanel" aria-labelledby="dentalcare">
                                <img src="assets/images/medical/orthopedic.jpg" class="img-fluid rounded shadow" alt="">
                                <div class="mt-4">
                                    <h5>Orthopadic Service</h5>
                                    <p class="text-muted my-3">This is required when, for example, the final text is not
                                        yet available. Dummy text is also known as 'fill text'. It is said that song
                                        composers of the past used dummy texts as lyrics.</p>
                                    <a href="javascript:void(0)" class="text-primary">Learn More <i-feather
                                            name="arrow-right" class="fea icon-sm"></i-feather></a>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                </ul>

                <div [ngbNavOutlet]="nav" class="ms-4 col-md-8"></div>
            </div>
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End -->


<!-- Start -->
<section class="section pt-0">
    <div class="container">
        <div class="position-relative" style="z-index: 1;">
            <div class="rounded shadow bg-white p-4">
                <div class="row" id="counter">
                    <div class="col-md-4">
                        <div class="counter-box text-center">
                            <h1 class="mb-0 mt-3"><span class="counter-value" data-target="99">99</span>.00%</h1>
                            <h5 class="counter-head mb-1">Positive feedback</h5>
                            <p class="text-muted mb-0">From Doctors</p>
                        </div>
                        <!--end counter box-->
                    </div>
                    <!--end col-->

                    <div class="col-md-4 mt-4 pt-2 mt-sm-0 pt-sm-0">
                        <div class="counter-box text-center">
                            <h1 class="mb-0 mt-3"><span class="counter-value" data-target="25">25</span>+</h1>
                            <h5 class="counter-head mb-1">Experienced Clinics</h5>
                            <p class="text-muted mb-0">High Qualified</p>
                        </div>
                        <!--end counter box-->
                    </div>
                    <!--end col-->

                    <div class="col-md-4 mt-4 pt-2 mt-sm-0 pt-sm-0">
                        <div class="counter-box text-center">
                            <h1 class="mb-0 mt-3"><span class="counter-value" [CountTo]="1100" [from]="312"
                                    [duration]="4"></span>+</h1>
                            <h5 class="counter-head mb-1">Questions & Answers</h5>
                            <p class="text-muted mb-0">Your Questions</p>
                        </div>
                        <!--end counter box-->
                    </div>
                    <!--end col-->
                </div>
                <!--end row-->
            </div>
            <div class="pt-5">
                <app-clients-logo></app-clients-logo>
            </div>
        </div>
        <div class="feature-posts-placeholder bg-primary bg-gradient"></div>
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End -->

<!-- Start -->
<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center mb-4 pb-2">
                    <h4 class="title mb-4">Our Qualified Doctors</h4>
                    <p class="text-muted para-desc mx-auto mb-0">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.</p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
            <div class="col-lg-3 col-md-6 mt-4 pt-2">
                <div class="card team text-center border-0 p-4 shadow">
                    <div class="position-relative">
                        <img src="assets/images/medical/01.jpg"
                            class="img-fluid avatar avatar-ex-large rounded-circle shadow" alt="">
                        <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                        </ul>
                        <!--end icon-->
                    </div>
                    <div class="card-body py-3 px-0 content">
                        <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Ronny Jofra</a></h5>
                        <small class="designation text-muted">Cardiologist</small>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-6 mt-4 pt-2">
                <div class="card team text-center border-0 p-4 shadow">
                    <div class="position-relative">
                        <img src="assets/images/medical/02.jpg"
                            class="img-fluid avatar avatar-ex-large rounded-circle shadow" alt="">
                        <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                        </ul>
                        <!--end icon-->
                    </div>
                    <div class="card-body py-3 px-0 content">
                        <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Micheal Carlo</a></h5>
                        <small class="designation text-muted">Surgeon</small>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-6 mt-4 pt-2">
                <div class="card team text-center border-0 p-4 shadow">
                    <div class="position-relative">
                        <img src="assets/images/medical/03.jpg"
                            class="img-fluid avatar avatar-ex-large rounded-circle shadow" alt="">
                        <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                        </ul>
                        <!--end icon-->
                    </div>
                    <div class="card-body py-3 px-0 content">
                        <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Aliana Rosy</a></h5>
                        <small class="designation text-muted">Physician</small>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-6 mt-4 pt-2">
                <div class="card team text-center border-0 p-4 shadow">
                    <div class="position-relative">
                        <img src="assets/images/medical/04.jpg"
                            class="img-fluid avatar avatar-ex-large rounded-circle shadow" alt="">
                        <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                        </ul>
                        <!--end icon-->
                    </div>
                    <div class="card-body py-3 px-0 content">
                        <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Sofia Razaq</a></h5>
                        <small class="designation text-muted">Chemist</small>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title mb-4">What Our Patients Says</h4>
                    <p class="text-muted para-desc mx-auto mb-0">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.</p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
        <app-testimonial [testimonialData]="testimonialData"></app-testimonial>
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End -->

<!-- Start -->
<div class="container-fluid px-0">
    <div class="py-5 bg-footer">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-7">
                    <div class="section-title">
                        <div class="d-flex">
                            <i class="uil uil-stethoscope-alt display-4 text-white title-dark"></i>
                            <div class="flex-1 ms-md-4 ms-3">
                                <h4 class="fw-bold text-light title-dark mb-1">Get a free medical</h4>
                                <p class="text-white-50 mb-0">Build modern looking websites fast and easy using
                                    Landflow.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-md-5 mt-4 mt-sm-0">
                    <div class="text-md-end ms-5 ms-sm-0">
                        <a href="javascript:void(0)" class="btn btn-primary me-2 me-lg-2 me-md-0 my-2"
                            data-bs-toggle="modal" data-bs-target="#appointment-request"><i
                                class="uil uil-file-alt"></i> Book An Appointment</a>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </div>
    <!--end div-->
</div>
<!--end container-->
<!-- End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->