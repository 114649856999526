<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="page-next-level">
                    <h2> {{productName}}</h2>
                    <div class="page-next">
                        <nav aria-label="breadcrumb" class="d-inline-block">
                            <ul class="breadcrumb bg-white rounded shadow mb-0">
                                <li class="breadcrumb-item"><a routerLink="/index">{{'COMPANY-NAME' | translate}}</a></li>
                                <li class="breadcrumb-item"><a href="javascript:void(0)">{{'SERVICES' | translate}}</a></li>
                                <li class="breadcrumb-item"><a routerLink="/products">{{'PRODUCTS' | translate}}</a></li>
                                <li class="breadcrumb-item active" aria-current="page">
                                    {{ productName }} </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
</div>
<!--Shape End-->

<!-- Blog STart -->
<section class="section">
    <div class="container">
        <div class="row">
            <!-- BLog Start -->
            <div class="col-lg-12 col-lg-12 col-md-12 col-12">
                <div class="card blog blog-detail border-0 shadow rounded">
                    <div class="text-center">
                        <img *ngIf="productData" src="{{productData.image}}" width="250" height="auto" class="img-fluid rounded-top pt-4" alt="">

                    </div>
                    <div class="card-body content">
                        <ul *ngIf="productData" class="list-inline mb-0 mt-3 products-list">
                            <li *ngFor="let detail of productData.details" class="list-inline-item">
                                {{detail}}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <!-- BLog End -->

        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Blog End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->