<!-- Start Hero -->
<!-- Hero Start -->
<section class="bg-home d-flex align-items-center" style="background: url('assets/images/crypto/bg.jpg') top;"
    id="home">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="title-heading text-center">
                    <h4 class="heading fw-bold text-white title-dark mb-3">The World's Most Popular Way to Buy, <br>
                        Hold, and Use Crypto</h4>
                    <h5 class="para-desc mx-auto text-light title-dark">Automated. Precise. Efficient.</h5>
                    <div class="mt-4 pt-2">
                        <a href="javascript:void(0)" class="btn btn-primary">Get Started</a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->
<!--end section-->
<!-- End Hero -->

<!-- Start -->
<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center mb-4 pb-2">
                    <h4 class="title mb-4">More than just numbers and charts</h4>
                    <h5 class="text-muted para-desc mx-auto mb-0">Enterprise Solutions For The Modern Blockchain
                        Business</h5>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div class="card border-0 features feature-clean course-feature p-4 overflow-hidden shadow text-center">
                    <div class="icons text-primary text-center mx-auto">
                        <i class="uil uil-file-contract-dollar d-block rounded h3 mb-0"></i>
                    </div>
                    <div class="card-body p-0 mt-4">
                        <a href="javascript:void(0)" class="title h5 text-dark">Auto Track & Sync</a>
                        <p class="text-muted mt-2">Composed in a pseudo-Latin language which more or less corresponds.
                        </p>
                        <a href="javascript:void(0)" class="text-primary read-more">Read More <i
                                class="uil uil-angle-right-b"></i></a>
                        <i class="uil uil-file-contract-dollar text-primary full-img"></i>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div class="card border-0 features feature-clean course-feature p-4 overflow-hidden shadow text-center">
                    <div class="icons text-primary text-center mx-auto">
                        <i class="uil uil-analytics d-block rounded h3 mb-0"></i>
                    </div>
                    <div class="card-body p-0 mt-4">
                        <a href="javascript:void(0)" class="title h5 text-dark">CPA Financial Tools</a>
                        <p class="text-muted mt-2">Composed in a pseudo-Latin language which more or less corresponds.
                        </p>
                        <a href="javascript:void(0)" class="text-primary read-more">Read More <i
                                class="uil uil-angle-right-b"></i></a>
                        <i class="uil uil-analytics text-primary full-img"></i>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div class="card border-0 features feature-clean course-feature p-4 overflow-hidden shadow text-center">
                    <div class="icons text-primary text-center mx-auto">
                        <i class="uil uil-dashboard d-block rounded h3 mb-0"></i>
                    </div>
                    <div class="card-body p-0 mt-4">
                        <a href="javascript:void(0)" class="title h5 text-dark">Real-time Dashboard</a>
                        <p class="text-muted mt-2">Composed in a pseudo-Latin language which more or less corresponds.
                        </p>
                        <a href="javascript:void(0)" class="text-primary read-more">Read More <i
                                class="uil uil-angle-right-b"></i></a>
                        <i class="uil uil-dashboard text-primary full-img"></i>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div class="card border-0 features feature-clean course-feature p-4 overflow-hidden shadow text-center">
                    <div class="icons text-primary text-center mx-auto">
                        <i class="uil uil-swatchbook d-block rounded h3 mb-0"></i>
                    </div>
                    <div class="card-body p-0 mt-4">
                        <a href="javascript:void(0)" class="title h5 text-dark">Bookkeeping</a>
                        <p class="text-muted mt-2">Composed in a pseudo-Latin language which more or less corresponds.
                        </p>
                        <a href="javascript:void(0)" class="text-primary read-more">Read More <i
                                class="uil uil-angle-right-b"></i></a>
                        <i class="uil uil-swatchbook text-primary full-img"></i>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div class="card border-0 features feature-clean course-feature p-4 overflow-hidden shadow text-center">
                    <div class="icons text-primary text-center mx-auto">
                        <i class="uil uil-exchange d-block rounded h3 mb-0"></i>
                    </div>
                    <div class="card-body p-0 mt-4">
                        <a href="javascript:void(0)" class="title h5 text-dark">Transaction Control</a>
                        <p class="text-muted mt-2">Composed in a pseudo-Latin language which more or less corresponds.
                        </p>
                        <a href="javascript:void(0)" class="text-primary read-more">Read More <i
                                class="uil uil-angle-right-b"></i></a>
                        <i class="uil uil-exchange text-primary full-img"></i>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div class="card border-0 features feature-clean course-feature p-4 overflow-hidden shadow text-center">
                    <div class="icons text-primary text-center mx-auto">
                        <i class="uil uil-lock-alt d-block rounded h3 mb-0"></i>
                    </div>
                    <div class="card-body p-0 mt-4">
                        <a href="javascript:void(0)" class="title h5 text-dark">Security & Privacy</a>
                        <p class="text-muted mt-2">Composed in a pseudo-Latin language which more or less corresponds.
                        </p>
                        <a href="javascript:void(0)" class="text-primary read-more">Read More <i
                                class="uil uil-angle-right-b"></i></a>
                        <i class="uil uil-lock-alt text-primary full-img"></i>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <img src="assets/images/crypto/lapy01.png" class="img-fluid" alt="">
            </div>
            <!--end col-->

            <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="section-title ms-lg-5">
                    <h4 class="title mb-4">Crypto Management for Businesses</h4>
                    <p class="text-muted">Due to its widespread use as filler text for layouts, non-readability is of
                        great importance: human perception is tuned to recognize certain patterns and repetitions in
                        texts. If the distribution of letters visual impact.</p>
                    <ul class="list-unstyled text-muted">
                        <li class="mb-0"><span class="text-primary h5 me-2"><i
                                    class="uil uil-check-circle align-middle"></i></span>Digital Marketing Solutions for
                            Tomorrow</li>
                        <li class="mb-0"><span class="text-primary h5 me-2"><i
                                    class="uil uil-check-circle align-middle"></i></span>Create your own skin to match
                            your brand</li>
                    </ul>
                    <a href="javascript:void(0)" class="mt-3 h6 text-primary">Find Out More <i
                            class="uil uil-angle-right-b"></i></a>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="section-title me-lg-5">
                    <h4 class="title mb-4">Smart Tools for Finance Teams</h4>
                    <p class="text-muted">This prevents repetitive patterns from impairing the overall visual impression
                        and facilitates the comparison of different typefaces. Furthermore, it is advantageous when the
                        dummy text is relatively realistic.</p>
                    <ul class="list-unstyled text-muted">
                        <li class="mb-0"><span class="text-primary h5 me-2"><i
                                    class="uil uil-check-circle align-middle"></i></span>Digital Marketing Solutions for
                            Tomorrow</li>
                        <li class="mb-0"><span class="text-primary h5 me-2"><i
                                    class="uil uil-check-circle align-middle"></i></span>Create your own skin to match
                            your brand</li>
                    </ul>
                    <a href="javascript:void(0)" class="mt-3 h6 text-primary">Find Out More <i
                            class="uil uil-angle-right-b"></i></a>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-5 col-md-6 order-1 order-md-2">
                <img src="assets/images/crypto/iphone.png" class="img-fluid" alt="">
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End -->

<!-- Partners start -->
<section class="py-4 border-bottom border-top">
    <div class="container py-4">
        <app-clients-logo></app-clients-logo>
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Partners End -->

<!-- Start CTA -->
<section class="section pb-0" style="background: url('assets/images/crypto/bg.jpg') center;">
    <div class="bg-overlay bg-primary bg-gradient" style="opacity: 0.7;"></div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center mb-4 pb-2">
                    <h4 class="title text-white title-dark mb-4">From Zero to Crypto in Minutes.</h4>
                    <p class="text-light para-dark para-desc mx-auto">Start working with Landrick that can provide
                        everything you need to generate awareness, drive traffic, connect.</p>
                </div>
                <div class="subcribe-form mt-4">
                    <form>
                        <div class="mb-2">
                            <input type="email" id="email" name="email" class="rounded-pill" placeholder="E-mail :">
                            <button type="submit" class="btn btn-pills btn-primary">Subscribe</button>
                        </div>
                    </form>
                    <!--end form-->
                </div>
                <!--end subscribe form-->
                <div class="mt-4 pt-2">
                    <img src="assets/images/crypto/cta.png" class="img-fluid d-block mx-auto" alt="">
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End CTA -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->