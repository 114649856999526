<!-- Hero Start -->
<section class="bg-half d-table w-100" id="home" style="background: url('assets/images/company/aboutus.jpg');">
    <div class="bg-overlay"></div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="page-next-level title-heading">
                    <h1 class="text-white title-dark"> {{'ABOUT-US' | translate}} </h1>
                    <p class="text-white-50 para-desc mb-0 mx-auto">
                        {{'SLOGAN' | translate}}
                    </p>
                    <div class="page-next">
                        <nav aria-label="breadcrumb" class="d-inline-block">
                            <ul class="breadcrumb bg-white rounded shadow mb-0">
                                <li class="breadcrumb-item"><a routerLink="/index">{{'COMPANY-NAME' | translate}}</a></li>
                                <li class="breadcrumb-item"><a href="javascript:void(0)">{{'COMPANY' | translate}}</a></li>
                                <li class="breadcrumb-item active" aria-current="page">{{'ABOUT-US' | translate}}</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
    <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
    </div>
</div>
<!-- Hero End -->

<section class="section">
    <div class="container">
        <div class="row align-items-center" id="counter">
            <div class="col-md-6">
                <img src="assets/images/company/about2.png" class="img-fluid" alt="">
            </div>
            <!--end col-->

            <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="ms-lg-4">
                    <div class="section-title">
                        <h4 class="title mb-4">{{'ABOUT-HEADER' | translate}}</h4>
                        <p class="text-muted"> {{'ABOUT-SECTION' | translate}} </p>
                        <br>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-5">
        <app-clients-logo></app-clients-logo>
    </div>
    <!--end container-->
</section>
<!--end section-->

<section class="section bg-light">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center mb-4 pb-2">
                    <!-- <h6 class="text-primary">{{'WORK-PROCESS' | translate}}</h6> -->
                    <h4 class="title mb-4">{{'HOW-WORK' | translate}}</h4>
                    <p class="text-muted para-desc mx-auto mb-0">{{'SLOGAN-P1' | translate}}
                        <span class="text-primary fw-bold">{{'COMPANY-NAME' | translate}}</span> {{'SLOGAN-P2' | translate}}</p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
            <div class="col-md-4 mt-4 pt-2">
                <div class="card features feature-clean work-process bg-transparent process-arrow border-0 text-center">
                    <div class="icons text-primary text-center mx-auto">
                        <i class="uil uil-presentation-edit d-block rounded h3 mb-0"></i>
                    </div>

                    <div class="card-body">
                        <h5 class="text-dark"> {{'PLANNING' | translate}} </h5>
                        <p class="text-muted mb-0">{{'PLANNING-DESC' | translate}}
                        </p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-4 mt-md-5 pt-md-3 mt-4 pt-2">
                <div class="card features feature-clean work-process bg-transparent process-arrow border-0 text-center">
                    <div class="icons text-primary text-center mx-auto">
                        <i class="uil uil-airplay d-block rounded h3 mb-0"></i>
                    </div>

                    <div class="card-body">
                        <h5 class="text-dark">{{'DESIGN' | translate}}</h5>
                        <p class="text-muted mb-0">{{'DESIGN-DESC-P1' | translate}}
                            <span class="text-primary fw-bold">{{'DESIGN-DESC-P2' | translate}}</span> {{'DESIGN-DESC-P3' | translate}}
                        </p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-4 mt-md-5 pt-md-5 mt-4 pt-2">
                <div class="card features feature-clean work-process bg-transparent d-none-arrow border-0 text-center">
                    <div class="icons text-primary text-center mx-auto">
                        <i class="uil uil-image-check d-block rounded h3 mb-0"></i>
                    </div>

                    <div class="card-body">
                        <h5 class="text-dark">{{'TESTING' | translate}}</h5>
                        <p class="text-muted mb-0">{{'TESTING-DESC' |translate}}
                        </p>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row align-items-end mb-4 pb-4">
            <div class="col-md-8">
                <div class="section-title text-md-left">
                    <h6 class="text-primary">{{'SERVICES' | translate}}</h6>
                    <h4 class="title mb-4">{{'WHAT-DO' | translate}}</h4>
                    <p class="text-muted mb-0 para-desc">{{'SLOGAN-P1' | translate}} <span class="text-primary fw-bold">{{'COMPANY-NAME' | translate}}</span> {{'SLOGAN-P2' | translate}}</p>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-4 mt-4 mt-sm-0">
                <div class="text-center text-md-end">
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="d-flex">
            <ul ngbNav #nav="ngbNav" orientation="vertical" class="nav-pills bg-white rounded shadow p-3 mb-0 mt-4 col-md-4
          pt-2">
                <li ngbNavItem="top">
                    <a ngbNavLink class="rounded">
                        <div class="text-center pt-1 pb-1">
                            <h6 class="mb-0">{{'MOBILE-APPS' | translate}}</h6>
                        </div>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="bg-white show active p-4 rounded shadow text-center" id="developing" role="tabpanel" aria-labelledby="webdeveloping">
                            <img src="assets/images/servicesImgs/mobile-development.jpg" class="img-fluid rounded shadow" alt="">
                            <div class="mt-4">
                                <p class="text-muted">{{'MOBILE-APPS-CONTENT' | translate}}
                                </p>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li ngbNavItem>
                    <a ngbNavLink class="rounded">
                        <div class="text-center pt-1 pb-1">
                            <h6 class="mb-0">{{'WEB-APPS' | translate}}</h6>
                        </div>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="bg-white p-4 rounded shadow text-center" id="data-analise" role="tabpanel" aria-labelledby="database">
                            <img src="assets/images/servicesImgs/web-development.jpg" class="img-fluid rounded shadow" alt="">
                            <div class="mt-4">
                                <p class="text-muted">{{'WEB-APPS-CONTENT' | translate}}
                                </p>
                            </div>
                        </div>
                        <!--end teb pane-->
                    </ng-template>
                </li>
                <li ngbNavItem>
                    <a ngbNavLink class="rounded">
                        <div class="text-center pt-1 pb-1">
                            <h6 class="mb-0">{{'CLOUD-APPS' | translate}}</h6>
                        </div>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="bg-white p-4 rounded shadow text-center" id="security" role="tabpanel" aria-labelledby="server">
                            <img src="assets/images/servicesImgs/cloud.jpg" class="img-fluid rounded shadow" alt="">
                            <div class="mt-4">
                                <p class="text-muted">{{'CLOUD-APPS-CONTENT' | translate}}
                                </p>
                            </div>
                        </div>
                        <!--end teb pane-->
                    </ng-template>
                </li>
                <li ngbNavItem>
                    <a ngbNavLink class="rounded">
                        <div class="text-center pt-1 pb-1">
                            <h6 class="mb-0">{{'DIS-SYS' | translate}}</h6>
                        </div>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="bg-white p-4 rounded shadow text-center" id="designing" role="tabpanel" aria-labelledby="webdesigning">
                            <img src="assets/images/servicesImgs/iot.jpg" class="img-fluid rounded shadow" alt="">
                            <div class="mt-4">
                                <p class="text-muted">{{'DIS-SYS-CONTENT-P' | translate}}
                                </p>
                            </div>
                        </div>
                        <!--end teb pane-->
                    </ng-template>
                </li>
                <li ngbNavItem>
                    <a ngbNavLink class="rounded">
                        <div class="text-center pt-1 pb-1">
                            <h6 class="mb-0">{{'SOFT-CONS' | translate}}</h6>
                        </div>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="bg-white p-4 rounded shadow text-center" id="designing" role="tabpanel" aria-labelledby="webdesigning">
                            <img src="assets/images/servicesImgs/consulting.jpg" class="img-fluid rounded shadow" alt="">
                            <div class="mt-4">
                                <p class="text-muted">{{'SOFT-CONS-CONTENT' | translate}}
                                </p>
                            </div>
                        </div>
                        <!--end teb pane-->
                    </ng-template>
                </li>
                <li ngbNavItem>
                    <a ngbNavLink class="rounded">
                        <div class="text-center pt-1 pb-1">
                            <h6 class="mb-0">{{'STRATEGY' | translate}}</h6>
                        </div>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="bg-white p-4 rounded shadow text-center" id="designing" role="tabpanel" aria-labelledby="webdesigning">
                            <img src="assets/images/servicesImgs/strategy.jpg" class="img-fluid rounded shadow" alt="">
                            <div class="mt-4">
                                <p class="text-muted">{{'STRATEGY-CONTENT' | translate}}
                                </p>
                            </div>
                        </div>
                        <!--end teb pane-->
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-4 ms-4"></div>
        </div>
        <!--end col-->

        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->

<!-- Start -->

<!--end section-->
<!-- End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->