<section class="bg-half-170 d-table w-100">
    <div class="container">
        <div class="row mt-5 align-items-center">
            <div class="col-lg-7 col-md-7">
                <div class="title-heading me-lg-4">
                    <h1 class="heading mb-3">{{'OUR-SLOGAN-P1' | translate}}
                        <span class="text-primary">{{'OUR-SLOGAN-P2' | translate}}</span> {{'OUR-SLOGAN-P3' | translate}}
                    </h1>
                    <p class="para-desc text-muted"> {{'SLOGAN' | translate}} </p>
                    <div class="mt-4">
                        <a routerLink="/about-us" class="btn btn-primary mt-2 me-2">
                            <i *ngIf="en; else ar" class="uil uil-angle-left-b"></i>
                            <ng-template #ar>
                                <i class="uil uil-angle-right-b"></i>

                            </ng-template>

                            {{'SEE-MORE' | translate}}</a>
                        <a routerLink="/contact-us" class="btn btn-outline-primary mt-2"><i class="uil uil-phone"></i>
              {{'CONTACT' | translate}}</a>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <img src="assets/images/illustrator/Startup_SVG.svg" alt="">
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!--end section-->
<!-- Hero End -->

<!-- Partners start -->
<section class="py-4 border-bottom border-top">
    <div class="container">
        <app-clients-logo></app-clients-logo>
    </div>
</section>
<!--end section-->
<!-- Partners End -->

<!-- How It Work Start -->
<section class="section bg-light border-bottom">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title mb-4">{{'HOW-WORK' | translate}}</h4>
                    <p class="text-muted para-desc mb-0 mx-auto">{{'SLOGAN-P1' | translate}} <span class="text-primary fw-bold">{{'COMPANY-NAME' | translate}}</span> {{'SLOGAN-P2' | translate}}</p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row align-items-center">
            <div class="col-lg-5 col-md-6 mt-4 pt-2">
                <img src="assets/images/illustrator/SEO_SVG.svg" alt="">
            </div>
            <!--end col-->

            <div class="col-lg-7 col-md-6 mt-4 pt-2">
                <div class="section-title ms-lg-5">
                    <h4 class="title mb-4">{{'HOME-WEB-SECTION-TITLE' | translate}}</h4>
                    <p class="text-muted">{{'HOME-WEB-SECTION-SUBTITLE' | translate}}</p>
                    <ul class="list-unstyled text-muted">
                        <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>{{'HOME-WEB-SECTION-DESC1' | translate}}</li>
                        <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>{{'HOME-WEB-SECTION-DESC2' | translate}}</li>
                        <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>{{'HOME-WEB-SECTION-DESC1' | translate}}</li>
                    </ul>
                    <!-- <a href="javascript:void(0)" class="mt-3 h6 text-primary">Find Out More <i
              class="uil uil-angle-right-b align-middle"></i></a> -->
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="section-title">
                    <h4 class="title mb-4"> {{'HOME-SECONED-SECTION-TITLE' | translate}} </h4>
                    <p class="text-muted">{{'HOME-SECONED-SECTION-SUBTITLE' | translate}}</p>
                    <ul class="list-unstyled text-muted">
                        <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>{{'HOME-SECONED-SECTION-DESC1' | translate}}</li>
                        <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>{{'HOME-SECONED-SECTION-DESC2' | translate}}</li>
                        <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>{{'HOME-SECONED-SECTION-DESC3' | translate}}</li>
                    </ul>
                    <!-- <a href="javascript:void(0)" class="mt-3 h6 text-primary">Find Out More <i
              class="uil uil-angle-right-b align-middle"></i></a> -->
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-5 col-md-6 order-1 order-md-2">
                <div class="card rounded border-0 shadow ms-lg-5">
                    <div class="card-body">
                        <img src="assets/images/illustrator/Mobile_notification_SVG.svg" alt="">

                        <div class="content mt-4 pt-2">
                            <form>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="mb-3">
                                            <label class="form-label">{{'HOME-FORM-NAME' | translate}} :<span class="text-danger">*</span></label>
                                            <div class="form-icon position-relative">
                                                <i-feather name="user" class="fea icon-sm icons"></i-feather>
                                                <input type="text" class="form-control ps-5" placeholder="{{'HOME-FORM-NAME' | translate}}" name="name" required="">
                                            </div>
                                        </div>
                                    </div>
                                    <!--end col-->

                                    <div class="col-lg-12">
                                        <div class="mb-3">
                                            <label class="form-label">{{'HOME-FORM-EMAIL' | translate}} : <span class="text-danger">*</span></label>
                                            <div class="form-icon position-relative">
                                                <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                                                <input type="email" class="form-control ps-5" placeholder="{{'HOME-FORM-EMAIL' | translate}}" name="email" required="">
                                            </div>
                                        </div>
                                    </div>
                                    <!--end col-->

                                    <div class="col-lg-12">
                                        <div class="mb-3">
                                            <label class="form-label">{{'HOME-FORM-PASS' | translate}} : <span class="text-danger">*</span></label>
                                            <div class="form-icon position-relative">
                                                <i-feather name="key" class="fea icon-sm icons"></i-feather>
                                                <input type="password" class="form-control ps-5" placeholder="{{'HOME-FORM-PASS' | translate}}" required="">
                                            </div>
                                        </div>
                                    </div>
                                    <!--end col-->

                                    <div class="col-lg-12 mt-2 mb-0">
                                        <div class="d-grid">
                                            <button class="btn btn-primary">Download</button>
                                        </div>
                                    </div>
                                    <!--end col-->
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- How It Work End -->

<!-- Testi Start -->
<section class="section pb-0">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <!-- <h6>We believe in building each other and hence</h6> -->
                    <h4 class="title mb-4">{{'PARTNERS-HEAD' | translate}}</h4>
                    <p class="text-muted para-desc mx-auto mb-0">{{'SLOGAN-P1' | translate}} <span class="text-primary fw-bold">{{'COMPANY-NAME' | translate}}</span> {{'SLOGAN-P2' | translate}}</p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row partners">
            <div class="col-12 mt-4 pt-2">
                <owl-carousel-o id="customer-testi" [options]="customOptions">
                    <ng-template carouselSlide>
                        <div class="card customer-testi text-center border rounded mx-2">
                            <div class="card-body">
                                <img src="assets/images/partners/halapina.jpg" class="img-fluid avatar avatar-ex-sm mx-auto partner_img" alt="">
                                <p class="text-muted mt-4">" {{'PARTNER-HALAPENIA' | translate}}"</p>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="card customer-testi text-center border rounded mx-2">
                            <div class="card-body">
                                <img src="assets/images/partners/colombia.jpg" class="img-fluid avatar avatar-ex-sm mx-auto partner_img" alt="">
                                <p class="text-muted mt-4"> {{'PARTNER-COLOMBIA' | translate}} </p>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="card customer-testi text-center border rounded mx-2">
                            <div class="card-body">
                                <img src="assets/images/partners/arabica.jpg" class="img-fluid avatar avatar-ex-sm mx-auto partner_img" alt="">
                                <p class="text-muted mt-4">{{'PARTNER-ARABICIA' | translate}}</p>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="card customer-testi text-center border rounded mx-2">
                            <div class="card-body">
                                <img src="assets/images/partners/smokey.jpg" class="img-fluid avatar avatar-ex-sm mx-auto partner_img" alt="">
                                <p class="text-muted mt-4"> {{'PARTNER-SMOKEY' | translate}} </p>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="card customer-testi text-center border rounded mx-2">
                            <div class="card-body">
                                <img src="assets/images/partners/mixBox.jpg" class="img-fluid avatar avatar-ex-sm mx-auto partner_img" alt="">
                                <p class="text-muted mt-4"> {{'PARTNER-MIXBOX' | translate}} </p>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="card customer-testi text-center border rounded mx-2">
                            <div class="card-body">
                                <img src="assets/images/partners/Alhoor.jpg" class="img-fluid avatar avatar-ex-sm mx-auto partner_img" alt="">
                                <p class="text-muted mt-4"> {{'PARTNER-ALHOOR' | translate}} </p>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="card customer-testi text-center border rounded mx-2">
                            <div class="card-body">
                                <img src="assets/images/partners/kheirZaman.jpg" class="img-fluid avatar avatar-ex-sm mx-auto partner_img" alt="">
                                <p class="text-muted mt-4"> {{'PARTNER-KHEIRZAMAN' | translate}} </p>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="card customer-testi text-center border rounded mx-2">
                            <div class="card-body">
                                <img src="assets/images/partners/Dania.jpg" class="img-fluid avatar avatar-ex-sm mx-auto partner_img" alt="">
                                <p class="text-muted mt-4"> {{'PARTNER-DANIA' | translate}} </p>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="card customer-testi text-center border rounded mx-2">
                            <div class="card-body">
                                <img src="assets/images/partners/faal.jpeg" class="img-fluid avatar avatar-ex-sm mx-auto partner_img" alt="">
                                <p class="text-muted mt-4"> {{'PARTNER-FALLY' | translate}} </p>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Testi End -->



<!-- FAQ n Contact Start -->
<section class="section bg-light">
    <div class="container">
        <!-- <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex">
                    <i-feather name="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i-feather>
                    <div class="flex-1">
                        <h5 class="mt-0">How our <span class="text-primary">Landrick</span> work ?</h5>
                        <p class="answer text-muted mb-0">Due to its widespread use as filler text for layouts, non-readability is of great importance: human perception is tuned to recognize certain patterns and repetitions in texts.</p>
                    </div>
                </div>
            </div>
]
            <div class="col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="d-flex">
                    <i-feather name="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i-feather>
                    <div class="flex-1">
                        <h5 class="mt-0"> What is the main process open account ?</h5>
                        <p class="answer text-muted mb-0">If the distribution of letters and 'words' is random, the reader will not be distracted from making a neutral judgement on the visual impact</p>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-12 mt-4 pt-2">
                <div class="d-flex">
                    <i-feather name="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i-feather>
                    <div class="flex-1">
                        <h5 class="mt-0"> How to make unlimited data entry ?</h5>
                        <p class="answer text-muted mb-0">Furthermore, it is advantageous when the dummy text is relatively realistic so that the layout impression of the final publication is not compromised.</p>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-12 mt-4 pt-2">
                <div class="d-flex">
                    <i-feather name="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i-feather>
                    <div class="flex-1">
                        <h5 class="mt-0"> Is <span class="text-primary">Landrick</span> safer to use with my account ?</h5>
                        <p class="answer text-muted mb-0">The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century. Lorem Ipsum is composed in a pseudo-Latin language which more or less corresponds to 'proper' Latin.</p>
                    </div>
                </div>
            </div>
        </div> -->
        <!--end row-->

        <div class="row my-md-5 pt-md-3 my-4 pt-2 pb-lg-4 justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title">
                    <h4 class="title mb-4">{{'QUESTIONS' | translate}}</h4>
                    <p class="text-muted para-desc mx-auto">{{'SLOGAN-P1' | translate}} <span class="text-primary fw-bold">{{'COMPANY-NAME' | translate}}</span> {{'SLOGAN-P2' | translate}}</p>
                    <a href="contact-us" class="btn btn-primary mt-4"><i class="mdi mdi-phone"></i> {{'CONTACT' | translate}}</a>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
    <div class="shape overflow-hidden text-footer">
        <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
    </svg>
    </div>
</div>
<!-- FAQ n Contact End -->


<!-- Back to top -->
<a href="#" [ngxScrollTo]="'#home'" id="back-to-top" class="btn btn-icon btn-primary back-to-top">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->