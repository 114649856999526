<!-- Start Style switcher -->
<div id="style-switcher" style="left: 0px;" class="bg-light border p-3 pt-2 pb-2" [style]="isVisible ? 'left: 0px' : 'left: -189px' ">
    <div>
        <h6 class="title text-center pb-2">{{'LANG' | translate}}</h6>
        <ul class="text-center list-unstyled mb-0">
            <select class="language btn btn-block btn-primary" (change)="changeLang($event)">
                <option class=" rtl-version mt-2" *ngFor="let lang of lang" [value]="lang" [selected]="lang === translate.currentLang">
                    {{ lang }}
                </option>
            </select>


            <!-- <li class="d-grid">
                <a id="en" data-value="en" href="javascript:void(0)" class="btn btn-sm btn-block btn-primary ltr-version t-ltr-light mt-2" (click)="setLtr()">en</a>
            </li>
            <li class="d-grid">
                <a id="ar" data-value="ar" href="javascript:void(0)" class="btn btn-sm btn-block btn-primary rtl-version t-rtl-light mt-2" (click)="setRtl()">ar</a>
            </li> -->
            <!-- <li class="d-grid"><a href="javascript:void(0)" class="btn btn-sm btn-block btn-primary rtl-version t-rtl-light mt-2" (click)="setRtl()">RTL</a>
            </li>

            <li class="d-grid"><a href="javascript:void(0)" class="btn btn-sm btn-block btn-primary ltr-version t-ltr-light mt-2" (click)="setLtr()">LTR</a>
            </li> -->
        </ul>
    </div>
    <div class="bottom">
        <a href="javascript: void(0);" class="settings bg-white title-bg-dark shadow d-block" (click)="onChangeSwitch()"><i
                class="mdi mdi-cog ms-1 mdi-24px position-absolute mdi-spin text-primary"></i></a>
    </div>
</div>