import { map } from 'rxjs/internal/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Product } from '../interfaces/product';
// import 'rxjs/add/operator/map'
// import 'rxjs/Rx';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private _http: HttpClient) { 
  }

  gatAllProductsAr():Observable<Product[]> {
    let url = '../../assets/data/products-ar.json'
    return this._http.get<Product[]>(url)
  }

  gatAllProductsEn():Observable<Product[]> {
    let url = '../../assets/data/products-en.json'
    return this._http.get<Product[]>(url)
  }

  getProductByIdEn(id) {
    let url = '../../assets/data/products-en.json'

    return this._http.get(url).pipe(
      map( (res: any) => res.filter(x=>x.id==id)
      ));
  }

  getProductByIdAr(id) {
    let url = '../../assets/data/products-ar.json'

    return this._http.get(url).pipe(
      map( (res: any) => res.filter(x=>x.id==id)
      ));
  }
}
