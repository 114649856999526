import { Product } from './../../../../interfaces/product';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.css']
})
export class ProductDetailsComponent implements OnInit {
  productData: Product
  productId: string
  productName: string
  allProducts: Product[]

  en: boolean = false
  lang: string = ''
  constructor(private _route: ActivatedRoute, private _prdServ: ProductsService) { }

  ngOnInit(): void {
    this.productId = this._route.snapshot.params.productId
    this.productName = this._route.snapshot.params.productName
    this.lang = localStorage.getItem("lang")
    if(this.lang === 'en') {
      this.en = true
      this.getAllProductsEn()
      this.getProductByIdEn();
    }
    else {
      this.getAllProductsAr();
      this.getProductByIdAr();
    }






  }


  getAllProductsAr() {
    return this._prdServ.gatAllProductsAr().subscribe(
      (res: any)=> {
        console.log(res);
        this.allProducts = res
      },
      (error)=> {
        console.log(error);       
      }
    )
  }


  
  getAllProductsEn() {
    return this._prdServ.gatAllProductsEn().subscribe(
      (res: any)=> {
        console.log(res);
        this.allProducts = res
      },
      (error)=> {
        console.log(error);       
      }
    )
  }

  getProductByIdEn() {
    this._prdServ.getProductByIdEn(this.productId).subscribe(
      (res: any) => {
        console.log(res);
        this.productData = res[0];
      },
      (error)=> {
        console.log(error);     
      }
    ) 
  }


  getProductByIdAr() {
    console.log(this.productId);
    console.log(typeof(this.productId));
    
    this._prdServ.getProductByIdAr(this.productId).subscribe(
      (res: any) => {
        console.log(res);
        this.productData = res[0];
      },
      (error)=> {
        console.log(error);     
      }
    ) 
  }



}
