<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="page-next-level">
                    <h4 class="title"> {{'TERMS' | translate}} </h4>
                    <div class="page-next">
                        <nav aria-label="breadcrumb" class="d-inline-block">
                            <ul class="breadcrumb bg-white rounded shadow mb-0">
                                <li class="breadcrumb-item"><a routerLink="/index">{{'COMPANY-NAME' | translate}}</a></li>
                                <li class="breadcrumb-item"><a href="javascript:void(0)">{{'COMPANY' | translate}}</a></li>
                                <li class="breadcrumb-item active" aria-current="page">{{'TERMS' | translate}} </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
</div>
<!--Shape End-->

<!-- Start Terms & Conditions -->
<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-9">
                <div class="card shadow border-0 rounded">
                    <div class="card-body">
                        <h5 class="card-title">{{'INTRO' | translate}} :</h5>
                        <p>{{'INTRO-CONTENT-P1' | translate}}</p>
                        <p class="text-muted">
                            {{'INTRO-CONTENT-P2' | translate}}
                        </p>

                        <h5 class="card-title">{{'PURCHASES' | translate}} :</h5>
                        <p>{{'PURCHASES-CONT-P1' | translate}}</p>
                        <br>
                        <p class="text-muted">
                            {{'PURCHASES-CONT-P2' | translate}}
                            <a routerLink="/products">{{'PRODUCTS' | translate}}</a>
                        </p>


                        <h5 class="card-title">{{'CONTENT' |translate}} :</h5>
                        <p>{{'CONTENT-P1' | translate}}
                            <b class="text-danger">{{'COMPANY-NAME' | translate}}</b>
                        </p>
                        <p>
                            <b class="text-danger">{{'COMPANY-NAME' | translate}}</b> {{'CONTENT-P2' | translate}}
                        </p>



                        <h5 class="card-title">{{'CHANGES' | translate}} :</h5>
                        <p>{{'CHANGES-CONTENT' | translate}}</p>

                        <div class="mt-3">
                            <p class="text-danger">{{'TERMS-CONTACT' | translate}}</p>
                            <a routerLink="/contact-us" class="btn btn-primary mt-2 me-2">{{'CONTACT' | translate}}</a>

                        </div>

                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End Terms & Conditions -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->