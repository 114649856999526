import { LanguageService } from './../../services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-switcher',
  templateUrl: './switcher.component.html',
  styleUrls: ['./switcher.component.css']
})

/**
 * Switcher component
 */
export class SwitcherComponent implements OnInit {
  lang: Array<any>
  currentLang: string
  en: boolean = false;
  isVisible: boolean;

  constructor(private translate: TranslateService, private languageService: LanguageService) { 
    this.lang = ['en', 'ar'];
    this.currentLang = '';
    const lang = localStorage.getItem('lang') || 'en';
    translate.setDefaultLang(lang);
    translate.use(lang);
  }

  ngOnInit(): void {
    this.isVisible = false;

    let lang = localStorage.getItem('lang');

    if(lang === 'en') {
      this.en = true
      document.getElementById('theme-opt').setAttribute('href', './assets/css/style.min.css');
    }
    else {
      document.getElementById('theme-opt').setAttribute('href', './assets/css/style-rtl.min.css');

    }
  }


  
  changeLang(event: any) {
    window.location.reload();
    const language = event.target.value;
    
    this.languageService.changeAppLanguage(language);
    localStorage.setItem("lang", language)
  }




  onChangeColor(color: string) {
    document
      .getElementById('color-opt')
      .setAttribute('href', './assets/css/colors/' + color + '.css');
  }

  // setRtl() {
  //   const language = document.getElementById("ar").getAttribute("data-value");
  //   this.languageService.changeAppLanguage(language);
  //   localStorage.setItem("lang", language)
  //   console.log(language);
  // }
  // setLtr() {
  //   const language = document.getElementById("en").getAttribute("data-value");
  //   this.languageService.changeAppLanguage(language);
  //   localStorage.setItem("lang", language)
  //   console.log(language);
  // }

  onChangeSwitch() {
    this.isVisible = !this.isVisible;
  }

}
