<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="page-next-level">
                    <h4 class="title"> {{'PRODUCTS' | translate}}</h4>
                    <div class="page-next">
                        <nav aria-label="breadcrumb" class="d-inline-block">
                            <ul class="breadcrumb bg-white rounded shadow mb-0">
                                <li class="breadcrumb-item"><a routerLink="/index">{{'COMPANY-NAME' | translate}}</a></li>
                                <li class="breadcrumb-item"><a href="javascript:void(0)">{{'SERVICES' | translate}}</a></li>
                                <li class="breadcrumb-item active" aria-current="page">{{'PRODUCTS' | translate}}</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
    <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
    </div>
</div>
<!-- Hero End -->

<!--Blog Lists Start-->
<section class="section">
    <div class="container">
        <div *ngIf="en; else Ar" class="row">
            <div class="col-lg-6 col-12 mb-4 pb-2" *ngFor="let product of allProducts | paginate: { itemsPerPage: 10, currentPage: p }">
                <div class="card blog rounded border-0 shadow overflow-hidden">
                    <div class="row align-items-center g-0">
                        <div class="col-md-6">
                            <img src="{{product.image}}" class="img-fluid" alt="">
                            <div class="overlay bg-dark"></div>
                        </div>
                        <div class="col-md-6">
                            <div class="card-body content">
                                <h5><a href="javascript:void(0)" class="card-title title text-dark">{{product.name}}</a>
                                </h5>
                                <p class="text-muted mb-0">{{product.brief}}</p>
                                <div class="post-meta d-flex justify-content-between mt-3">
                                    <a routerLink="/products/product-details/{{product.id}}/{{product.name}}" class="text-muted readmore">{{'SEE-MORE' | translate}} <i
                      class="uil uil-angle-right-b align-middle"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="text-center">
                <pagination-controls (pageChange)="p = $event"></pagination-controls>
            </div>

        </div>
        <ng-template #Ar>
            <div class="row">
                <div class="col-lg-6 col-12 mb-4 pb-2" *ngFor="let product of allProducts | paginate: { itemsPerPage: 10, currentPage: p }">
                    <div class="card blog rounded border-0 shadow overflow-hidden">
                        <div class="row align-items-center g-0">
                            <div class="col-md-6">
                                <img src="{{product.image}}" class="img-fluid" alt="">
                                <div class="overlay bg-dark"></div>
                            </div>
                            <div class="col-md-6">
                                <div class="card-body content">
                                    <h5><a href="javascript:void(0)" class="card-title title text-dark">{{product.name}}</a>
                                    </h5>
                                    <p class="text-muted mb-0">{{product.brief}}</p>
                                    <div class="post-meta d-flex justify-content-between mt-3">
                                        <a routerLink="/products/product-details/{{product.id}}/{{product.name}}" class="text-muted readmore">{{'SEE-MORE' | translate}} <i
                                               class="uil uil-angle-left-b align-middle"></i>
                                       </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center">
                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                </div>

            </div>
        </ng-template>
    </div>
</section>

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->