<!-- Hero Start -->
<section class="bg-half d-table w-100" id="home" style="background: url('assets/images/company/aboutus.jpg');">
    <div class="bg-overlay"></div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="page-next-level title-heading">
                    <h1 class="text-white title-dark"> {{'ABOUT-US' | translate}} </h1>
                    <p class="text-white-50 para-desc mb-0 mx-auto">
                        {{'SLOGAN' | translate}}
                    </p>
                    <div class="page-next">
                        <nav aria-label="breadcrumb" class="d-inline-block">
                            <ul class="breadcrumb bg-white rounded shadow mb-0">
                                <li class="breadcrumb-item"><a routerLink="/index">{{'COMPANY-NAME' | translate}}</a></li>
                                <li class="breadcrumb-item"><a href="javascript:void(0)">{{'COMPANY' | translate}}</a></li>
                                <li class="breadcrumb-item active" aria-current="page">{{'ABOUT-US' | translate}}</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
    <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
    </div>
</div>
<!-- Hero End -->

<section class="section">
    <div class="container">
        <div class="row align-items-center" id="counter">
            <div class="col-md-6">
                <img src="assets/images/company/about2.png" class="img-fluid" alt="">
            </div>
            <!--end col-->

            <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="ms-lg-4">
                    <!-- <div class="d-flex mb-4">
                        <span class="text-primary h1 mb-0"><span class="counter-value display-1 fw-bold" [CountTo]="15" [from]="0"
                [duration]="4"></span>+</span>
                        <span class="h6 align-self-end ms-2">Years <br> Experience</span>
                    </div> -->
                    <div class="section-title">
                        <h4 class="title mb-4">{{'ABOUT-HEADER' | translate}}</h4>
                        <p class="text-muted"> {{'ABOUT-SECTION' | translate}} </p>
                        <br>
                        <!-- <h4 class="title mb-4">{{'VISION' | translate}}</h4>
                        <p class="text-muted"> {{'VISION-DESC' | translate}} </p> -->
                        <!-- <a routerLink="/page-contact-three" href="javascript:void(0)" class="btn btn-primary mt-3">{{'CONTACT' | translate}}</a> -->
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-5">
        <app-clients-logo></app-clients-logo>
    </div>
    <!--end container-->
</section>
<!--end section-->

<section class="section bg-light">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center mb-4 pb-2">
                    <h6 class="text-primary">{{'WORK-PROCESS' | translate}}</h6>
                    <h4 class="title mb-4">{{'HOW-WORK' | translate}}</h4>
                    <p class="text-muted para-desc mx-auto mb-0">{{'SLOGAN-P1' | translate}}
                        <span class="text-primary fw-bold">{{'COMPANY-NAME' | translate}}</span> {{'SLOGAN-P2' | translate}}</p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
            <div class="col-md-4 mt-4 pt-2">
                <div class="card features feature-clean work-process bg-transparent process-arrow border-0 text-center">
                    <div class="icons text-primary text-center mx-auto">
                        <i class="uil uil-presentation-edit d-block rounded h3 mb-0"></i>
                    </div>

                    <div class="card-body">
                        <h5 class="text-dark"> {{'PLANNING' | translate}} </h5>
                        <p class="text-muted mb-0">{{'PLANNING-DESC' | translate}}
                        </p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-4 mt-md-5 pt-md-3 mt-4 pt-2">
                <div class="card features feature-clean work-process bg-transparent process-arrow border-0 text-center">
                    <div class="icons text-primary text-center mx-auto">
                        <i class="uil uil-airplay d-block rounded h3 mb-0"></i>
                    </div>

                    <div class="card-body">
                        <h5 class="text-dark">{{'DESIGN' | translate}}</h5>
                        <p class="text-muted mb-0">{{'DESIGN-DESC-P1' | translate}}
                            <span class="text-primary fw-bold">{{'DESIGN-DESC-P2' | translate}}</span> {{'DESIGN-DESC-P3' | translate}}
                        </p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-4 mt-md-5 pt-md-5 mt-4 pt-2">
                <div class="card features feature-clean work-process bg-transparent d-none-arrow border-0 text-center">
                    <div class="icons text-primary text-center mx-auto">
                        <i class="uil uil-image-check d-block rounded h3 mb-0"></i>
                    </div>

                    <div class="card-body">
                        <h5 class="text-dark">{{'TESTING' | translate}}</h5>
                        <p class="text-muted mb-0">{{'TESTING-DESC' |translate}}
                        </p>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row align-items-end mb-4 pb-4">
            <div class="col-md-8">
                <div class="section-title text-md-left">
                    <h6 class="text-primary">{{'SERVICES' | translate}}</h6>
                    <h4 class="title mb-4">{{'WHAT-DO' | translate}}</h4>
                    <p class="text-muted mb-0 para-desc">{{'SLOGAN-P1' | translate}} <span class="text-primary fw-bold">{{'COMPANY-NAME' | translate}}</span> {{'SLOGAN-P2' | translate}}</p>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-4 mt-4 mt-sm-0">
                <div class="text-center text-md-end">
                    <a href="javascript:void(0)" class="text-primary h6">{{'SEE-MORE' 
                      | translate}}
            <i-feather name="arrow-right" class="fea icon-sm"></i-feather>
          </a>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="d-flex">
            <ul ngbNav #nav="ngbNav" orientation="vertical" class="nav-pills bg-white rounded shadow p-3 mb-0 mt-4 col-md-4
          pt-2">
                <li ngbNavItem="top">
                    <a ngbNavLink class="rounded">
                        <div class="text-center pt-1 pb-1">
                            <h6 class="mb-0">{{'MOBILE-APPS' | translate}}</h6>
                        </div>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="bg-white show active p-4 rounded shadow text-center" id="developing" role="tabpanel" aria-labelledby="webdeveloping">
                            <img src="assets/images/servicesImgs/mobile-development.jpg" class="img-fluid rounded shadow" alt="">
                            <div class="mt-4">
                                <p class="text-muted">{{'MOBILE-APPS-CONTENT' | translate}}
                                </p>
                                <a href="javascript:void(0)" class="text-primary">See More
                  <i-feather name="arrow-right" class="fea icon-sm"></i-feather>
                </a>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li ngbNavItem>
                    <a ngbNavLink class="rounded">
                        <div class="text-center pt-1 pb-1">
                            <h6 class="mb-0">{{'WEB-APPS' | translate}}</h6>
                        </div>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="bg-white p-4 rounded shadow text-center" id="data-analise" role="tabpanel" aria-labelledby="database">
                            <img src="assets/images/servicesImgs/web-development.jpg" class="img-fluid rounded shadow" alt="">
                            <div class="mt-4">
                                <p class="text-muted">{{'WEB-APPS-CONTENT' | translate}}
                                </p>
                                <a href="javascript:void(0)" class="text-primary">See More
                  <i-feather name="arrow-right" class="fea icon-sm"></i-feather>
                </a>
                            </div>
                        </div>
                        <!--end teb pane-->
                    </ng-template>
                </li>
                <li ngbNavItem>
                    <a ngbNavLink class="rounded">
                        <div class="text-center pt-1 pb-1">
                            <h6 class="mb-0">{{'CLOUD-APPS' | translate}}</h6>
                        </div>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="bg-white p-4 rounded shadow text-center" id="security" role="tabpanel" aria-labelledby="server">
                            <img src="assets/images/servicesImgs/cloud.jpg" class="img-fluid rounded shadow" alt="">
                            <div class="mt-4">
                                <p class="text-muted">{{'CLOUD-APPS-CONTENT' | translate}}
                                </p>
                                <a href="javascript:void(0)" class="text-primary">See More
                  <i-feather name="arrow-right" class="fea icon-sm"></i-feather>
                </a>
                            </div>
                        </div>
                        <!--end teb pane-->
                    </ng-template>
                </li>
                <li ngbNavItem>
                    <a ngbNavLink class="rounded">
                        <div class="text-center pt-1 pb-1">
                            <h6 class="mb-0">{{'DIS-SYS' | translate}}</h6>
                        </div>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="bg-white p-4 rounded shadow text-center" id="designing" role="tabpanel" aria-labelledby="webdesigning">
                            <img src="assets/images/servicesImgs/iot.jpg" class="img-fluid rounded shadow" alt="">
                            <div class="mt-4">
                                <p class="text-muted">This is required when, for example, the final text is not yet available. Dummy text is also known as 'fill text'. It is said that song composers of the past used dummy texts as lyrics.
                                </p>
                                <a href="javascript:void(0)" class="text-primary">See More
                  <i-feather name="arrow-right" class="fea icon-sm"></i-feather>
                </a>
                            </div>
                        </div>
                        <!--end teb pane-->
                    </ng-template>
                </li>
                <li ngbNavItem>
                    <a ngbNavLink class="rounded">
                        <div class="text-center pt-1 pb-1">
                            <h6 class="mb-0">{{'SOFT-CONS' | translate}}</h6>
                        </div>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="bg-white p-4 rounded shadow text-center" id="designing" role="tabpanel" aria-labelledby="webdesigning">
                            <img src="assets/images/servicesImgs/consulting.jpg" class="img-fluid rounded shadow" alt="">
                            <div class="mt-4">
                                <p class="text-muted">{{'SOFT-CONS-CONTENT' | translate}}
                                </p>
                                <a href="javascript:void(0)" class="text-primary">{{'SEE-MORE' | translate}}
                <i-feather name="arrow-right" class="fea icon-sm"></i-feather>
              </a>
                            </div>
                        </div>
                        <!--end teb pane-->
                    </ng-template>
                </li>
                <li ngbNavItem>
                    <a ngbNavLink class="rounded">
                        <div class="text-center pt-1 pb-1">
                            <h6 class="mb-0">{{'STRATEGY' | translate}}</h6>
                        </div>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="bg-white p-4 rounded shadow text-center" id="designing" role="tabpanel" aria-labelledby="webdesigning">
                            <img src="assets/images/servicesImgs/strategy.jpg" class="img-fluid rounded shadow" alt="">
                            <div class="mt-4">
                                <p class="text-muted">This is required when, for example, the final text is not yet available. Dummy text is also known as 'fill text'. It is said that song composers of the past used dummy texts as lyrics.
                                </p>
                                <a href="javascript:void(0)" class="text-primary">See More
              <i-feather name="arrow-right" class="fea icon-sm"></i-feather>
            </a>
                            </div>
                        </div>
                        <!--end teb pane-->
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-4 ms-4"></div>
        </div>
        <!--end col-->

        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->

<!-- Start -->
<section class="section bg-light pt-0">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="video-solution-cta position-relative" style="z-index: 1;">
                    <div class="position-relative">
                        <img src="assets/images/cta-bg.jpg" class="img-fluid rounded-md shadow-lg" alt="">
                        <div class="play-icon">
                            <a (click)="openWindowCustomClass(content)" href="javascript:void(0);" class="play-btn video-play-icon">
                                <i class="mdi mdi-play text-primary rounded-circle bg-white shadow-lg"></i>
                            </a>
                            <ng-template #content let-modal>
                                <div class="modal-header">
                                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                                </div>
                                <div class="modal-body">
                                    <youtube-player [videoId]="'yba7hPeTSjk'" height="450" width="780">
                                    </youtube-player>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                    <div class="content mt-md-4 pt-md-2">
                        <div class="row justify-content-center">

                            <div class="col-lg-10 text-center">
                                <div class="row align-items-center">
                                    <div class="col-md-6 mt-4 pt-2">
                                        <div class="section-title text-md-start">
                                            <h6 class="text-white-50">Team</h6>
                                            <h4 class="title text-white title-dark mb-0">Meet Experience <br> Team Member</h4>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-12 mt-4 pt-md-2">
                                        <div class="section-title text-md-start">
                                            <p class="text-white-50 para-desc">{{'SLOGAN' | translate}}</p>
                                            <a href="javascript:void(0)" class="text-white title-dark">Read More
                        <i-feather name="arrow-right" class="fea icon-sm"></i-feather>
                      </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row -->
        <div class="feature-posts-placeholder bg-primary"></div>
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->