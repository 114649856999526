import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-privacy',
  templateUrl: './page-privacy.component.html',
  styleUrls: ['./page-privacy.component.css']
})

/**
 * PAge Privacy Component
 */
export class PagePrivacyComponent implements OnInit {
  lang: string
  en: boolean = false

  constructor() { }

  ngOnInit(): void {
    this.lang = localStorage.getItem("lang");
    if(this.lang == "en") {
      this.en = true
    }


  }

}
